import {request} from 'library/api';
import {path} from 'ramda';
import {EIntegrations} from 'slices/integration/lib/types';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsBnovoIntegrationDisableCreateResponse,
  HotelsBnovoIntegrationEnableCreateResponse,
  HotelsHotelPmsIntegrationEnableCreateResponse,
} from 'types/api-scheme';

type EnableIntegrationContext = {
  hotelId: Hotel['id'];
  integrationType: EIntegrations;
};

const enable = ({hotelId, integrationType}: EnableIntegrationContext) =>
  request<
    | HotelsBnovoIntegrationEnableCreateResponse
    | HotelsHotelPmsIntegrationEnableCreateResponse
  >(url(`hotels/${hotelId}/${integrationType}-integration/enable`), {
    method: 'POST',
    headers: clientHeaders(),
  }).then(
    path<
      | HotelsBnovoIntegrationEnableCreateResponse['data']
      | HotelsHotelPmsIntegrationEnableCreateResponse['data']
    >([1, 'data']),
  );

type DisableIntegrationContext = {
  hotelId: Hotel['id'];
  integrationType: EIntegrations;
};

const disable = ({hotelId, integrationType}: DisableIntegrationContext) =>
  request<HotelsBnovoIntegrationDisableCreateResponse>(
    url(`hotels/${hotelId}/${integrationType}-integration/disable`),
    {
      method: 'POST',
      headers: clientHeaders(),
    },
  ).then(
    path<HotelsBnovoIntegrationDisableCreateResponse['data']>([1, 'data']),
  );

export const integrationApi = {
  enable,
  disable,
};
