import {replace} from 'ramda';
import {
  ZHILIBYLI_MOBILE_APPS_ROUTE,
  ZHILIBYLI_OFFER_ROUTE,
  ZHILIBYLI_SUPPORT_ROUTE,
  ZHILIBYLI_URL,
  ZHILIBYLI_ABOUT_ROUTE,
  ZHILIBYLI_POLITICS_ROUTE,
  ZHILIBYLI_RULES_ROUTE,
  ZHILIBYLI_SOFTWARE_ROUTE,
  ZHILIBYLI_PUBLIC_OFFER_AGREEMENT_ROUTE,
  ZHILIBYLI_AFFILIATE_ROUTE,
  HOTELIERS_KNOWLEDGE_BASE_ROUTE,
  CONNECT_TRAVELLINE_ROUTE,
  CONNECT_REALTY_CALENDAR_ROUTE,
  CONNECT_BNOVO_ROUTE,
  HOW_TO_BOOK_ARTICLE_ROUTE,
  KNOWLEDGE_BASE_ROUTE,
  ZHILIBYLI_HELP_URL,
} from 'source/utilities/guards/environment';
import {Routes} from 'source/utilities/network/url';

export const isHotelPage = (pathname: string) =>
  pathname.startsWith(`${Routes.manage}/`) &&
  pathname.split(`${Routes.manage}/`)[1].length > 0 &&
  !pathname.includes(`${Routes.manage}/create`) &&
  !pathname.includes(`${Routes.manage}/profile`);

export const isErrorPage = (pathname: string) =>
  pathname.startsWith(Routes.notFoundPage) ||
  pathname.startsWith(Routes.serverErrorPage);

export const isNotRedirectPage = (pathname: string) =>
  pathname.startsWith(Routes.authorization) ||
  pathname.startsWith(Routes.registration) ||
  pathname.startsWith(Routes.home);

export const buildEditRoomRoute = (hotelId: Hotel['id'], roomId: Room['id']) =>
  `/manage/${hotelId}/rooms/${roomId}/edit`;

export const buildManageHomeRoute = (hotelId: Hotel['id']) =>
  `/manage/${hotelId}/home`;

export const buildAddRoomRoute = (hotelId: Hotel['id']) =>
  `/manage/${hotelId}/rooms/add`;

export const buildRoomsRoute = (hotelId: Hotel['id']) =>
  `/manage/${hotelId}/rooms`;

export const buildEditRateRoute = (hotelId: Hotel['id'], rateId?: Rate['id']) =>
  `/manage/${hotelId}/rates/${rateId}`;

export const buildBalanceDetailsRoute = (
  organizationId: ExistingRequisitesItem['id'],
) => `/balance/${organizationId}`;

export const getActiveLink = (
  href: string,
  pathname: string,
  isActive?: boolean,
) => {
  return isActive ?? replace(/\d+/, '[hotel_slug]', href) === pathname;
};

export const MAIN_ZHILIBYLI_ROUTES = {
  support: ZHILIBYLI_SUPPORT_ROUTE,
  mobileApps: ZHILIBYLI_MOBILE_APPS_ROUTE,
  offer: ZHILIBYLI_OFFER_ROUTE,
  about: ZHILIBYLI_ABOUT_ROUTE,
  politics: ZHILIBYLI_POLITICS_ROUTE,
  rules: ZHILIBYLI_RULES_ROUTE,
  software: ZHILIBYLI_SOFTWARE_ROUTE,
  publicOfferAgreement: ZHILIBYLI_PUBLIC_OFFER_AGREEMENT_ROUTE,
  affiliate: ZHILIBYLI_AFFILIATE_ROUTE,
} as const;

export const KNOWLEDGE_BASE_ROUTES = {
  hoteliers: HOTELIERS_KNOWLEDGE_BASE_ROUTE,
  connectTravelline: CONNECT_TRAVELLINE_ROUTE,
  connectRealtyCalendar: CONNECT_REALTY_CALENDAR_ROUTE,
  connectBnovo: CONNECT_BNOVO_ROUTE,
  howToBookArticle: HOW_TO_BOOK_ARTICLE_ROUTE,
  knowledgeBase: KNOWLEDGE_BASE_ROUTE,
} as const;

export const buildRouteToMain = (
  route: (typeof MAIN_ZHILIBYLI_ROUTES)[keyof typeof MAIN_ZHILIBYLI_ROUTES],
) => {
  return `${ZHILIBYLI_URL}${route}`;
};

export const buildRouteToHelp = (
  route: (typeof KNOWLEDGE_BASE_ROUTES)[keyof typeof KNOWLEDGE_BASE_ROUTES],
) => {
  return `${ZHILIBYLI_HELP_URL}${route}`;
};
