import styled from '@emotion/styled';
import {
  DragEvent,
  MouseEvent,
  FC,
  memo,
  useEffect,
  useContext,
  useCallback,
  UIEvent,
} from 'react';
import {equals, isNil} from 'ramda';

import {useBnovoIntegration} from 'source/utilities/hooks/use-is-bnovo-integration';
import {useAppDispatch, useAppSelector} from 'source/store';
import {CalendarRefContext} from 'source/context/calendar-ref';

import {CELL_GAP, SCROLL_CLASSNAME} from 'slices/calendar/lib/constants';
import {isDateInConstrainStayPeriod} from 'slices/calendar/lib/helpers/dates';
import {
  isRateCellRangeExist,
  isRateCellSelected,
  isRowSelected,
  onHeaderScroll,
  isRateCellUpdated,
  setInitialScrollsPosition,
  handleAppropriateScroll,
} from 'slices/calendar/lib/helpers/ui';
import {buildPriceForCountName} from 'slices/calendar/lib/helpers/prices';
import {
  RangeSelectCells,
  SelectedCell,
  SelectRangeType,
} from 'slices/calendar/lib/types';
import {
  setRangeSelectCells,
  setSelectedCell,
  setEditedCell,
  selectSubmitCells,
  selectEditedCell,
  selectNotAvailableDates,
  setSelectedRange,
  resetSelectedRange,
} from 'slices/calendar/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {ScrollContext} from 'source/context/scroll';
import {useDebounce} from 'source/utilities/hooks/use-debounce';
import {css} from '@emotion/react';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';
import {useHotelPmsIntegration} from 'source/utilities/hooks/use-is-hotel-pms-integration';
import {PriceButton} from './price-button';

const RightRow = styled.div<{isTouch: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${CELL_GAP}px;
  height: 50px;
  padding: 0px 5px;
  border-radius: 20px;
  overflow-x: scroll;
  overflow-y: hidden;

  &:hover {
    background-color: ${({theme}) => theme.palette.darkerBackground};
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  ${({isTouch}) =>
    isTouch &&
    css`
      scroll-behavior: smooth;
    `}
`;

interface Props extends RangeSelectCells {
  room: number;
  selectedCell: SelectedCell;
  stay: CalendarRatesConstraintsStay;
  isRateDisabled: boolean;
  selectedRange: SelectRangeType;
}

const RoomPrice: FC<Props> = ({
  prices,
  room,
  guestNumber,
  rateId,
  selectedCell,
  stay,
  isRateDisabled,
  selectedRange,
}) => {
  const dispatch = useAppDispatch();
  const isTouch = useDeviceDetection('touch');
  const submitCells = useAppSelector(selectSubmitCells);
  const storeNotAvailableDates = useAppSelector(selectNotAvailableDates);
  const notAvailableDates = storeNotAvailableDates[room];
  const [calendarRef] = useContext(CalendarRefContext);
  const [hasBnovoIntegration] = useBnovoIntegration();
  const [hasHotelPmsIntegration] = useHotelPmsIntegration();
  const [hasRealtyIntegration] = useRealtyIntegration();
  const [, setScroll] = useContext(ScrollContext);

  const editedCell = useAppSelector(selectEditedCell);

  const handleSetScroll = (value: number) => {
    setScroll(value);
  };
  const debouncedScroll = useDebounce(handleSetScroll, 500);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    handleAppropriateScroll(
      event.target,
      isTouch,
      (scrollLeft) => debouncedScroll(scrollLeft),
      (scrollLeft) => onHeaderScroll(scrollLeft, calendarRef),
    );
  };

  const handleCellClick = useCallback((cell: SelectedCell) => {
    dispatch(setSelectedCell(cell));
  }, []);

  const handleCellDoubleClick = useCallback((cell: SelectedCell) => {
    dispatch(setEditedCell(cell));
  }, []);

  const handleDragStart = (
    event: DragEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>,
    buildCell: SelectedCell & {guestNumber: number},
  ) => {
    const {currentTarget} = event;

    const newSelectedRange = {
      ...selectedRange,
      from: buildCell.column!,
      to: selectedRange.to,
      guestNumber: buildCell.guestNumber,
      rateId: buildCell.rate!,
      room: buildCell.room!,
      position: {
        x: currentTarget?.offsetLeft,
        y: currentTarget?.offsetTop,
      },
    };

    dispatch(setSelectedRange(newSelectedRange));
  };

  const handleDragOver = useCallback(
    (
      buildCell: SelectedCell,
      selectedCell: SelectedCell,
      selectedRange: SelectRangeType,
    ) => {
      const isCurrentRow = isRowSelected(
        buildCell.room,
        buildCell.row,
        selectedCell,
      );
      const isColumnLess = selectedCell.column > buildCell.column;
      const isColumnEqual = buildCell.column === selectedRange.to;

      if (isCurrentRow && !isColumnLess && !isColumnEqual) {
        const getNewSelectedRange = () => {
          if (buildCell.column) {
            return {
              ...selectedRange,
              to: buildCell.column,
            };
          }
          return selectedRange;
        };
        dispatch(setSelectedRange(getNewSelectedRange()));
      }
    },
    [],
  );

  const handleDragEnd = useCallback(
    (selectedRange: SelectRangeType) => {
      const rangeSelectCells = {
        rateId: selectedRange.rateId,
        guestNumber: selectedRange.guestNumber,
        prices: prices.slice(selectedRange.from, selectedRange.to + 1),
        room: selectedRange.room,
      };

      dispatch(setRangeSelectCells(rangeSelectCells));
      dispatch(resetSelectedRange());
    },
    [prices],
  );

  const handleSelectStart = useCallback(
    (
      event: DragEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>,
      buildCell: SelectedCell,
      guestNumber: number,
    ) => {
      handleCellClick(buildCell);
      handleDragStart(event, {
        ...buildCell,
        guestNumber,
      });
    },
    [],
  );

  useEffect(() => {
    setInitialScrollsPosition(calendarRef);
  }, []);

  return (
    <RightRow
      className={SCROLL_CLASSNAME}
      onScroll={handleScroll}
      isTouch={isTouch}
    >
      {prices.map((priceData, index) => {
        const {isDateBlocked} = isDateInConstrainStayPeriod(
          stay,
          new Date(priceData.date),
        );

        const buildCell = {
          room,
          column: index,
          row: buildPriceForCountName(guestNumber),
          rate: rateId,
          price: priceData.price,
          date: priceData.date,
          isDatePrice: priceData.is_date_price,
          guestNumber,
        } as SelectedCell;

        const isRoomSelected = buildCell.room === selectedRange.room;

        const rangeSelected =
          isRateCellRangeExist(
            selectedRange.from - 1,
            selectedRange.to,
            buildCell.column,
            selectedRange.guestNumber,
            guestNumber,
            selectedRange.rateId,
            buildCell.rate!,
          ) && isRoomSelected;

        const isRateSelected =
          isRateCellSelected(
            buildCell.room,
            buildCell.column!,
            buildCell.row!,
            buildCell.rate!,
            selectedCell,
          ) && !rangeSelected;

        const isRateEdit = isRateCellSelected(
          buildCell.room,
          buildCell.column!,
          buildCell.row!,
          buildCell.rate!,
          editedCell,
        );

        const isCellUpdated = submitCells[buildCell.room]?.some((cellElement) =>
          isRateCellUpdated(buildCell, cellElement),
        );

        const isNotAvailableDate = Boolean(
          notAvailableDates?.find(
            (notAvailableDate) => notAvailableDate === buildCell.date,
          ),
        );

        const hasNoPrice = isNil(priceData.price);

        const isDisabledCell =
          hasBnovoIntegration ||
          hasHotelPmsIntegration ||
          hasRealtyIntegration ||
          isRateDisabled ||
          isDateBlocked ||
          isNotAvailableDate ||
          hasNoPrice;

        return (
          <PriceButton
            room={room}
            index={index}
            rateId={rateId}
            key={priceData.date}
            handleDragOver={handleDragOver}
            handleDragEnd={handleDragEnd}
            disabled={isDisabledCell}
            updated={isCellUpdated}
            selected={isRateSelected}
            rangeSelected={rangeSelected}
            handleSelectStart={handleSelectStart}
            guestNumber={guestNumber}
            handleCellClick={handleCellClick}
            handleCellDoubleClick={handleCellDoubleClick}
            isRateEdit={isRateEdit}
            priceData={priceData}
            isRateSelected={isRateSelected}
            selectedCell={selectedCell}
            selectedRange={selectedRange}
          />
        );
      })}
    </RightRow>
  );
};

export default memo(RoomPrice, (previousProps, nextProps) => {
  // Для избежания лишних ререндеров ячеек используется мемо с кастомным коллбеком
  // Но при автообновлении/селекте необходимо их ререндерить при изменении некоторых параметров
  const currentRow = buildPriceForCountName(nextProps.guestNumber);
  const isSelectRowEqualWithCurrent = nextProps.selectedCell.row === currentRow;
  const isPreviousSelectRowEqualWithCurrent =
    previousProps.selectedCell.row === currentRow;
  const isPricesEqual = equals(previousProps.prices, nextProps.prices);
  const isStayEqual = equals(previousProps.stay, nextProps.stay);

  const isRateDisabledEqual =
    previousProps.isRateDisabled === nextProps.isRateDisabled;

  if (!isPricesEqual || !isStayEqual || !isRateDisabledEqual) {
    return false;
  }

  return !isSelectRowEqualWithCurrent && !isPreviousSelectRowEqualWithCurrent;
});
