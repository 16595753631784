import styled from '@emotion/styled';
import {FC, memo} from 'react';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

import {Tooltip} from 'library/components/tooltip';

import useTranslation from 'next-translate/useTranslation';
import {
  getConstraintLabelKey,
  getIntegrationConstraintLabelKey,
  shouldDisableRestrictionButton,
} from 'slices/calendar/lib/helpers/ui';
import {useBnovoIntegration} from 'source/utilities/hooks/use-is-bnovo-integration';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';
import {CELL_WIDTH} from 'slices/calendar/lib/constants';
import {DataButton} from '../data-button';
import {RestrictionTooltip} from '../../restriction-tooltip';
import {BnovoRestrictionTooltip} from '../../bnovo-restriction-tooltip';
import {RealtyRestrictionTooltip} from '../../realty-restriction-tooltip';

const DataCell = styled.div`
  min-width: ${CELL_WIDTH}px;
`;

const TooltipTrigger = styled.div`
  cursor: pointer;
`;
interface IProps {
  hasConstraint: boolean;
  stay: CalendarRatesConstraintsStay;
  rate: CalendarRate;
  constraints: CalendarRatesConstraints;
  bnovoConstraint: CalendarBnovoConstraint | null;
  realtyConstraint: CalendarRealtyConstraint | null;
  dayDate: Date;
}

const RestrictionCellComponent: FC<IProps> = ({
  hasConstraint,
  bnovoConstraint,
  realtyConstraint,
  stay,
  rate,
  constraints,
  dayDate,
}) => {
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation();
  const [hasBnovoIntegration] = useBnovoIntegration();

  const [hasRealtyIntegration] = useRealtyIntegration();
  const hasAnyIntegrationEnabled = hasBnovoIntegration || hasRealtyIntegration;

  const shouldDisableButton = shouldDisableRestrictionButton(
    hasBnovoIntegration,
    hasRealtyIntegration,
    hasConstraint,
    bnovoConstraint,
    realtyConstraint,
  );

  return (
    <DataCell>
      <Tooltip
        on={mobile ? 'click' : 'hover'}
        position="bottom left"
        minWidth={285}
        maxWidth={300}
        disabled={shouldDisableButton}
        mobileTopModal={mobile}
        trigger={
          <TooltipTrigger>
            <DataButton grey disabled={shouldDisableButton}>
              {hasAnyIntegrationEnabled
                ? getIntegrationConstraintLabelKey(
                    t,
                    hasBnovoIntegration,
                    hasRealtyIntegration,
                    bnovoConstraint,
                    realtyConstraint,
                  )
                : getConstraintLabelKey(t, hasConstraint, stay)}
            </DataButton>
          </TooltipTrigger>
        }
      >
        {hasBnovoIntegration && (
          <BnovoRestrictionTooltip
            bnovoConstraint={bnovoConstraint}
            rateId={rate.id}
            dayDate={dayDate}
          />
        )}
        {hasRealtyIntegration && (
          <RealtyRestrictionTooltip
            realtyConstraint={realtyConstraint}
            rateId={rate.id}
            dayDate={dayDate}
          />
        )}
        {!hasBnovoIntegration && !hasRealtyIntegration && (
          <RestrictionTooltip constraints={constraints} />
        )}
      </Tooltip>
    </DataCell>
  );
};

export const RestrictionCell = memo(RestrictionCellComponent);
