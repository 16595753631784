export type Pattern =
  | 'email'
  | 'phone'
  | 'url'
  | 'code'
  | 'bik'
  | 'account'
  | 'account_2'
  | 'inn'
  | 'kpp'
  | 'ogrn'
  | 'ogrnip'
  | 'testNotEmpty'
  | 'user_name'
  | 'textNotEmpty'
  | 'positiveNumber';

type Patterns = {
  [Key in Pattern]: RegExp;
} & {compatible(key: keyof Patterns): string};

export const patterns: Patterns = {
  email: /^(?!.*@xn--)[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/,
  textNotEmpty: /^(?!\s*$)(?=.*[A-Za-zЁА-яё]).{3,}$/,
  phone: /^\+\d{11}$/,
  user_name: /^[A-Za-zЁА-яё]+$/,
  url: /^(http|https):\/\/.+/,
  code: /^\d{6}$/,
  compatible(key) {
    return this[key].toString().slice(1).slice(0, -1);
  },
  account_2: /^(?: *\d *){16}(?! *\d) *$|^(?: *\d *){18}(?! *\d) *$/,
  account: /^(?: *\d *){20}(?! *\d) *$/,
  inn: /^(\d{10}|\d{12})$/,
  bik: /^\d{9}$/,
  kpp: /^\d{4}(\d{2})?\d{3}$/,
  ogrn: /^\d{13}$/,
  ogrnip: /^\d{15}$/,
  testNotEmpty: /.+/,
  positiveNumber: /^[1-9]\d*$/,
};
