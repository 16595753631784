import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {
  BUTTON_STYLES_BY_SIZE,
  BUTTON_STYLES_BY_TYPE,
} from 'library/components/button/lib/constants';
import {ButtonSize, ButtonType} from 'library/components/button/lib/types';
import {STYLE_BY_TEXT_SIZE, TextSize} from 'library/components/text';
import {ButtonHTMLAttributes, FC} from 'react';
import {Icon, IconName} from 'source/components/icon';

const StyledButton = styled.button<{
  buttonType: ButtonType;
  buttonSize: ButtonSize;
  textSize: TextSize;
  rounded: boolean;
  hoverOpacity: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({rounded}) => (rounded ? '12px 20px' : '20px 40px')};
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: ${({rounded}) => (rounded ? '100px' : '6px')};
  background-color: ${({theme}) => theme.palette.primaryAccent};
  transition: 200ms ease-in-out;
  opacity: ${({disabled}) => disabled && 0.4};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};

  ${({hoverOpacity, disabled}) =>
    hoverOpacity &&
    !disabled &&
    css`
      &:hover {
        opacity: 0.7;
      }
    `}

  ${({buttonType}) => BUTTON_STYLES_BY_TYPE[buttonType]}
  ${({buttonSize}) => BUTTON_STYLES_BY_SIZE[buttonSize]}
  ${({textSize}) => STYLE_BY_TEXT_SIZE[textSize]}
`;

const IconWrapper = styled.div<{
  disabled?: boolean;
  hasClickHandleExist: boolean;
}>`
  margin-right: 15px;
  cursor: ${({hasClickHandleExist}) =>
    hasClickHandleExist ? 'pointer' : 'auto'};
  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.4;
    `}
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ButtonProps = {
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  rounded?: boolean;
  textSize?: TextSize;
  hoverOpacity?: boolean;
  icon?: IconName;
  iconOnClick?: () => void;
  iconWidth?: number;
  iconHeight?: number;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FC<ButtonProps> = ({
  buttonType = 'action',
  buttonSize = 'L',
  rounded = false,
  hoverOpacity = false,
  textSize = 'boldS',
  icon,
  iconOnClick,
  iconWidth = 20,
  iconHeight = 20,
  disabled,
  children,
  ...attributes
}) => (
  <StyledButton
    buttonType={buttonType}
    rounded={rounded}
    buttonSize={buttonSize}
    hoverOpacity={hoverOpacity}
    textSize={textSize}
    disabled={disabled}
    {...attributes}
  >
    {icon && (
      <IconWrapper
        disabled={disabled}
        onClick={iconOnClick}
        hasClickHandleExist={typeof iconOnClick === 'function'}
      >
        <Icon name={icon} width={iconWidth} height={iconHeight} />
      </IconWrapper>
    )}
    <ContentWrapper>{children}</ContentWrapper>
  </StyledButton>
);
