import useTranslation from 'next-translate/useTranslation';
import {Link} from 'source/components/link';
import {
  LinkList,
  LinkListItem,
  LinkListTitle,
  LinkListWrapper,
  StyledText,
} from '../link-list';
import {
  ZHILIBYLI_ABOUT,
  ZHILIBYLI_AFFILIATE,
  ZHILIBYLI_POLITICS,
  ZHILIBYLI_RULES,
  ZHILIBYLI_SOFTWARE,
  ZHILIBYLI_PUBLIC_OFFER_AGREEMENT,
} from '../../lib/constants';

export const InformationColumn = () => {
  const {t} = useTranslation('footer');

  return (
    <LinkListWrapper>
      <LinkListTitle size="boldM">{t('info')}</LinkListTitle>
      <LinkList>
        <LinkListItem>
          <Link href={ZHILIBYLI_ABOUT}>
            <StyledText size="S">{t('about_company')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ZHILIBYLI_AFFILIATE}>
            <StyledText size="S">{t('partner_programme')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ZHILIBYLI_RULES}>
            <StyledText size="S">{t('terms_of_use')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ZHILIBYLI_POLITICS}>
            <StyledText size="S">{t('personal_data')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ZHILIBYLI_SOFTWARE}>
            <StyledText size="S">{t('additional_instruments')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ZHILIBYLI_PUBLIC_OFFER_AGREEMENT}>
            <StyledText size="S">{t('public_offer_agreement')}</StyledText>
          </Link>
        </LinkListItem>
      </LinkList>
    </LinkListWrapper>
  );
};
