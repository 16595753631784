/* eslint-disable camelcase */
import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {equals, isNil, without} from 'ramda';
import {
  FC,
  memo,
  useContext,
  useEffect,
  useState,
  UIEvent,
  useMemo,
  useCallback,
} from 'react';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';

import {useBnovoIntegration} from 'source/utilities/hooks/use-is-bnovo-integration';
import {checkIsEmptyObject} from 'source/utilities/object';
import {CalendarRefContext} from 'source/context/calendar-ref';
import {showWarnMessage} from 'source/utilities/exceptions/business';
import {HotelContext} from 'source/context/hotel';
import HumanIcon from 'source/components/icon/assets/human.svg';
import {store, useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {ArrowText} from 'library/components/arrow-text';
import {SubText, Text} from 'library/components/text';
import {RoomIcon} from 'library/icons/room-icon';
import {CancelIcon} from 'library/icons/cancel-icon';
import {ExclamationMarkIcon} from 'library/icons/exclamation-mark';

import {buildUpdatedRatesPrices} from 'slices/calendar/lib/helpers/prices';
import {
  blinkAnimation,
  isCellSelected,
  isRateRowSelected,
  isRowSelected,
  onHeaderScroll,
  isHorizontalScrollEnd,
  buildResetedCells,
  buildNewSubmitCells,
  handleAppropriateScroll,
  setInitialScrollsPosition,
  isHorizontalScrollStart,
} from 'slices/calendar/lib/helpers/ui';
import {
  isDateInConstrainStayPeriod,
  buildNewDatesOnScroll,
  getBnovoConstraint,
  getRealtyConstraint,
} from 'slices/calendar/lib/helpers/dates';
import {
  isRoomAvailabilityConstraint,
  createRoomContraintsProperties,
  buildAvailableRoomsNumber,
  buildCustomRoomAvailabilityCount,
  isConstraintEquals,
  buildNewNotAvailableDates,
  deleteRoomFromEdited,
  filterConstraintsById,
  buildNewRoomConstraintsOnRoomsNumberChange,
} from 'slices/calendar/lib/helpers/rooms';
import {
  SelectedCell,
  SubmitAvailabilityType,
  DayDatesType,
  ScrollDirectionType,
} from 'slices/calendar/lib/types';
import {updateCalendar, fetchCalendarData} from 'slices/calendar/network';
import {
  HUMAN_COUNTER_DIVIDER,
  SCROLL_CLASSNAME,
  HORIZONTAL_INFINITE_SCROLL_STEP,
  ALLOWED_OPENED_ROOMS_COUNT,
  CELL_GAP,
  CELL_WIDTH,
} from 'slices/calendar/lib/constants';
import {
  selectDaysDates,
  selectSelectedCell,
  selectSelectedDates,
  setSelectedCell,
  setUpdatedCells,
  selectUpdatedCells,
  selectSubmitCells,
  selectIsTimerEnabled,
  setSubmitCells,
  resetUpdatedCells,
  resetSubmitCells,
  setNotAvailableDates,
  selectNotAvailableDates,
  selectIsLoading,
  selectIsShowDisabledRates,
  setIsLoading,
  setIsNeedLoadMoreDates,
  selectIsNeedLoadMoreDates,
  setSelectedDates,
  selectEditRooms,
  setEditRooms,
  selectIsDatesLimitExceeded,
  setIsDatesLimitExceeded,
  setOpenedRooms,
  selectOpenedRooms,
  selectRatesPrices,
  setRatesPrices,
  selectDateAvailabilities,
  setDateAvailabilities,
  selectRoomConstraints,
  setRoomConstraints,
  selectSelectedRange,
  setMassEditModalFilters,
  selectMassEditModalFilters,
  resetRoomDataById,
  selectIsBeforeDatesLimitExceeded,
  setIsBeforeDatesLimitExceeded,
  selectIsNeedLoadBeforeDates,
  setIsNeedLoadBeforeDates,
} from 'slices/calendar/store';
import {RoomConstraint} from 'slices/calendar/ui/room-constraint';
import RoomPrice from 'slices/calendar/ui/room-price';

import {
  addCurrentYearDays,
  getSlashDateFormat,
  getViewMonthsNameFromBackendDate,
  parseRangeDateToBackendDateFormat,
} from 'source/utilities/dates';
import {toValueOrEmptyArray} from 'source/utilities/array';
import {ScrollContext} from 'source/context/scroll';
import {useDebounce} from 'source/utilities/hooks/use-debounce';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';
import {isSameDay} from 'date-fns';
import {useHotelPmsIntegration} from 'source/utilities/hooks/use-is-hotel-pms-integration';
import {ActionButtons} from '../action-buttons';
import {Loader} from '../loader';
import UpdateTimer from '../update-timer';
import MobileCalendarTooltip from '../mobile-calendar-tooltip';
import {RoomAvailabilitiesCell} from './room-availabilities-cell';
import {RestrictionCell} from './restriction-cell';
import {CancellationCell} from './cancellation-cell';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${CELL_GAP}px;
  min-height: 150px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${CELL_GAP}px;
`;

const RateDurationTitle = styled(SubText)`
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const RateDurationRow = styled(Row)`
  margin: 5px 0;
  gap: 5px;
  flex-wrap: wrap;
`;

const RoomsRow = styled(Row)`
  padding-top: 10px;
`;
const RestrictionsRow = styled(Row)``;
const PricesRow = memo(styled(Row)``);
const CancellationRow = styled(Row)``;

const RightRow = styled.div<{
  isTouch: boolean;
  height?: number;
  noGap?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${CELL_GAP}px;
  padding: 0px 5px;
  border-radius: 20px;
  overflow-x: scroll;
  overflow-y: hidden;

  height: ${({height}) => height || 50}px;

  &:hover {
    background-color: ${({theme}) => theme.palette.darkerBackground};
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  ${({isTouch}) =>
    isTouch &&
    css`
      scroll-behavior: smooth;
    `}

  ${({noGap}) =>
    noGap &&
    css`
      gap: 0;
    `}
`;

const FirstDataCell = styled.div<{mobile: boolean}>`
  min-width: ${({mobile}) => (mobile ? 60 : 90)}px;
`;

const FirstDataCellText = styled(Text)<{selected?: boolean}>`
  ${({theme, selected}) =>
    selected &&
    css`
      color: ${theme.palette.fontPrimary};
      font-weight: 700;
      animation: ${blinkAnimation} 0.3s;
    `}
`;

const EmptyCell = memo(styled.div<{minWidth: number}>`
  min-width: ${({minWidth}) => minWidth}px;
  content: ' ';
  height: 100%;
`);

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${CELL_WIDTH}px;
  height: 44px;
  border-radius: 20px;
  background-color: ${({theme}) => theme.palette.fontAccent};
  cursor: pointer;
  color: ${({theme}) => theme.palette.primaryAccent};
  border: ${({theme}) => `1px solid ${theme.palette.border}`};
`;

const Human = styled(HumanIcon)`
  width: 14px;
  height: 14px;
  color: ${({theme}) => theme.palette.fontPrimary};
`;

const HumanWrapper = styled.div<{mobile: boolean}>`
  width: ${CELL_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: ${({mobile}) => (mobile ? 'center' : 'flex-start')};
`;

interface Props {
  id: number;
  name: string;
  roomIndex: number;
}

export const Room: FC<Props> = ({id, name, roomIndex}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useDeviceDetection('mobile');
  const isTouch = useDeviceDetection('touch');
  const [calendarRef] = useContext(CalendarRefContext);
  const [hotel] = useContext(HotelContext);
  const selectedDates = useAppSelector(selectSelectedDates);
  const [, setScroll] = useContext(ScrollContext);
  const today = new Date();

  // даты, т.е дни месяцей, используется внутренний стейт для оптимизации ререндеров, он меняется только при реальном изменении значения в сторе
  const daysDates = useAppSelector(selectDaysDates);

  const selectedCell = useAppSelector(selectSelectedCell);
  const isNeedLoadMoreDates = useAppSelector(selectIsNeedLoadMoreDates);
  const isNeedLoadBeforeDates = useAppSelector(selectIsNeedLoadBeforeDates);
  const [isOpen, setIsOpen] = useState(false);

  // цены тарифов со стора, useMemo для оптимизации ререндеров
  const ratesPricesObject = useAppSelector(selectRatesPrices);
  const [ratesPrices, initialRatesPrices] = useMemo(() => {
    return [
      toValueOrEmptyArray(ratesPricesObject?.[id]?.ratesPrices),
      toValueOrEmptyArray(ratesPricesObject?.[id]?.initialRatesPrices),
    ];
  }, [ratesPricesObject?.[id], id]);

  // кол-во доступных номеров со стора, недоступные даты со стора, useMemo для оптимизации ререндеров
  const storeDateAvailabilities = useAppSelector(selectDateAvailabilities);
  const dateAvailabilities = useMemo(() => {
    return toValueOrEmptyArray(storeDateAvailabilities?.[id]);
  }, [storeDateAvailabilities?.[id], id]);

  // закрытия номеров со стора, useMemo для оптимизации ререндеров
  const storeRoomConstraints = useAppSelector(selectRoomConstraints);
  const [roomConstraints, initialRoomConstraints] = useMemo(() => {
    return [
      toValueOrEmptyArray(storeRoomConstraints?.[id]?.roomConstraints),
      toValueOrEmptyArray(storeRoomConstraints?.[id]?.initialRoomConstraints),
    ];
  }, [storeRoomConstraints?.[id], id]);
  const openedRooms = useAppSelector(selectOpenedRooms);

  const openedRoomsArray = Object.values(openedRooms).filter(Boolean);
  const massEditModalFilters = useAppSelector(selectMassEditModalFilters);

  const editRooms = useAppSelector(selectEditRooms);
  const editedRooms = useAppSelector(selectEditRooms);
  const isDatesLimitExceeded = useAppSelector(selectIsDatesLimitExceeded);
  const isBeforeDatesLimitExceeded = useAppSelector(
    selectIsBeforeDatesLimitExceeded,
  );
  const [hasHotelPmsIntegration] = useHotelPmsIntegration();
  const [hasBnovoIntegration] = useBnovoIntegration();
  const [hasRealtyIntegration] = useRealtyIntegration();
  const selectedRange = useAppSelector(selectSelectedRange);
  const [roomsNumberId, setRoomsNumberId] = useState<null | number>(null);
  const isEdit = editRooms ? editRooms[id] : false;

  const [isTimerUpdate, setIsTimerUpdate] = useState(false);
  const updatedCells = useAppSelector(selectUpdatedCells);
  const submitCells = useAppSelector(selectSubmitCells);
  const storeNotAvailableDates = useAppSelector(selectNotAvailableDates);
  const isTimerEnabled = useAppSelector(selectIsTimerEnabled);
  const isShowDisabledRates = useAppSelector(selectIsShowDisabledRates);
  const loading = useAppSelector(selectIsLoading);

  const onCellClick = (cell: SelectedCell) => {
    dispatch(setSelectedCell(cell));
  };

  const handleSetScroll = (value: number) => {
    setScroll(value);
  };
  const debouncedScroll = useDebounce(handleSetScroll, 500);

  const handleCompleteSave = (data?: UpdateManageCalendar) => {
    if (!data?.[0]) {
      return;
    }
    // Добавляет id с сервера к room_constraints, убирает удаленные
    const updatedConstraints = data[0].room_constraints;

    const newRoomConstraints = roomConstraints
      .map((previousItem: ClientCalendarRoomConstraints[number]) => {
        if (updatedConstraints.length === 0 || previousItem.id) {
          return previousItem;
        }

        let updatedConstraint = previousItem;

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, constraint] of updatedConstraints.entries()) {
          if (isConstraintEquals(constraint, previousItem)) {
            updatedConstraint = constraint;
            updatedConstraints.splice(index, 1);
            break;
          }
        }

        return updatedConstraint;
      })
      .filter(
        (item: ClientCalendarRoomConstraint) =>
          !isRoomAvailabilityConstraint(item) && !item.is_delete,
      );

    const newSubmitCells = buildResetedCells(id, submitCells);

    const updatedAvailableDates = data[0].availability_by_dates;

    // обновление отображаемых данных и initial данных (с помощью них отслеживаются изменения)
    dispatch(setSubmitCells(newSubmitCells));
    dispatch(
      setDateAvailabilities({
        [id]: updatedAvailableDates,
      }),
    );
    dispatch(
      setRatesPrices({
        [id]: {
          ratesPrices,
          initialRatesPrices: ratesPrices,
        },
      }),
    );
    dispatch(
      setRoomConstraints({
        [id]: {
          roomConstraints: newRoomConstraints,
          initialRoomConstraints: newRoomConstraints,
        },
      }),
    );

    dispatch(setEditRooms(deleteRoomFromEdited(editRooms, id)));
  };

  const handleSaveChanges = async () => {
    if (hotel && hotel.id) {
      dispatch(setIsLoading(true));

      const roomConstraintsDifference = without(
        initialRoomConstraints,
        roomConstraints,
      );

      await updateCalendar(
        hotel?.id,
        id,
        selectedDates.from,
        selectedDates.to,
        roomConstraintsDifference as SubmitAvailabilityType[],
        submitCells[id],
        handleCompleteSave,
        () => dispatch(setIsLoading(false)),
      );
    }
  };

  const onRoomAvailabilitiesComplete = (
    availabilities?: RoomAvailabilities,
  ) => {
    if (availabilities) {
      dispatch(
        setDateAvailabilities({
          [id]: availabilities,
        }),
      );
    }
  };

  const handleCancelChanges = () => {
    dispatch(
      setRatesPrices({
        [id]: {
          ratesPrices: initialRatesPrices,
          initialRatesPrices,
        },
      }),
    );

    dispatch(setEditRooms(deleteRoomFromEdited(editRooms, id)));

    dispatch(
      setRoomConstraints({
        [id]: {
          roomConstraints: initialRoomConstraints,
          initialRoomConstraints,
        },
      }),
    );

    const newUpdatedCells = buildResetedCells(id, updatedCells);
    const newSubmitCells = buildResetedCells(id, submitCells);

    dispatch(setUpdatedCells(newUpdatedCells));
    dispatch(setSubmitCells(newSubmitCells));
  };

  const onCalendarDataComplete = (calendarData?: ManageCalendar) => {
    if (calendarData) {
      const {room_constraints, rates, availability_by_date} = calendarData;

      onRoomAvailabilitiesComplete(availability_by_date);

      dispatch(
        setRoomConstraints({
          [id]: {
            roomConstraints: room_constraints,
            initialRoomConstraints: room_constraints,
          },
        }),
      );

      dispatch(
        setRatesPrices({
          [id]: {
            ratesPrices: rates,
            initialRatesPrices: rates,
          },
        }),
      );
    }
  };

  const onRoomDataCellClick = useCallback(
    (roomId: number, columnIndex: number, popupRoomId: number) => {
      onCellClick({
        room: roomId,
        column: columnIndex,
        row: 'rooms_count',
      });

      setRoomsNumberId(popupRoomId);
    },
    [],
  );

  const handleDatesAvailabilitiesPopupClose = useCallback(
    () => setRoomsNumberId(null),
    [],
  );

  const handleInfiniteScroll = (event: UIEvent<HTMLDivElement>) => {
    handleAppropriateScroll(
      event.target,
      isTouch,
      (scrollLeft) => debouncedScroll(scrollLeft),
      (scrollLeft) => onHeaderScroll(scrollLeft, calendarRef),
    );

    if (!isTouch) {
      dispatch(setIsNeedLoadMoreDates(isHorizontalScrollEnd(event)));
      dispatch(setIsNeedLoadBeforeDates(isHorizontalScrollStart(event)));
    }
  };

  const handleHeaderScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      handleAppropriateScroll(
        event.target,
        isTouch,
        (scrollLeft) => debouncedScroll(scrollLeft),
        (scrollLeft) => onHeaderScroll(scrollLeft, calendarRef),
      );
    },
    [calendarRef, isTouch, debouncedScroll],
  );

  const handleChangeRoomsNumber = useCallback(
    (value: number, item: DayDatesType[number]) => {
      setRoomsNumberId(null);
      const newRoomConstraints = buildNewRoomConstraintsOnRoomsNumberChange(
        roomConstraints,
        value,
        item,
      );

      dispatch(
        setRoomConstraints({
          [id]: {
            roomConstraints: newRoomConstraints,
            initialRoomConstraints,
          },
        }),
      );
    },
    [roomConstraints, initialRoomConstraints],
  );
  const onInfiniteScrollLoadComplete = (calendarData?: ManageCalendar) => {
    if (calendarData) {
      const {availability_by_date, room_constraints, rates} = calendarData;

      dispatch(
        setDateAvailabilities({
          [id]: availability_by_date,
        }),
      );

      dispatch(
        setRoomConstraints({
          [id]: {
            roomConstraints: filterConstraintsById(room_constraints),
            initialRoomConstraints: filterConstraintsById(room_constraints),
          },
        }),
      );

      dispatch(
        setRatesPrices({
          [id]: {
            ratesPrices: rates,
            initialRatesPrices: rates,
          },
        }),
      );
    }
  };

  const onOpenRoomWarning = () => {
    // Если открыто 3 номера, перед открытием следующего закрываем первый номер без изменений
    const editedRoomsArray = Object.keys(editedRooms);
    const openedRoomsArray = Object.keys(openedRooms);

    const notEditedRoomId = openedRoomsArray.find(
      (openedRoom) => !editedRoomsArray.includes(openedRoom),
    );

    if (!notEditedRoomId) {
      showWarnMessage(t('calendar:notify.save_changed_to_open_new_room'));

      return;
    }

    // Удаляем из объекта закрываемый рум
    const newOpenedRooms = deleteRoomFromEdited(openedRooms, notEditedRoomId);

    dispatch(setOpenedRooms({...newOpenedRooms, [id]: true}));
  };

  useEffect(() => {
    // Проверяет старый ratesPrices и обновляет только те элементы, которые были массово изменены
    if (updatedCells && updatedCells[id]?.length > 0) {
      // Так как у нас можно выбрать только 1 рейт в модалке, берем первый элемент = рейт у всех элементов одинаковый
      const {rate} = updatedCells[id][0];

      if (!rate) {
        return;
      }

      // Обновленные цены - нужно для отслеживания изменений (показ кнопок сохранить и отменить)
      const newRatesPrices = buildUpdatedRatesPrices(
        ratesPrices,
        updatedCells[id],
        rate,
      );

      dispatch(
        setRatesPrices({
          [id]: {
            ratesPrices: newRatesPrices,
            initialRatesPrices,
          },
        }),
      );

      // Информация по ценам, которая отправляется на бэк и показывает изменения в ui
      const newSubmitCells = buildNewSubmitCells(
        submitCells,
        updatedCells[id],
        id,
        rate,
      );

      dispatch(setSubmitCells(newSubmitCells));
      dispatch(setUpdatedCells(buildResetedCells(id, updatedCells)));
    }
  }, [updatedCells, submitCells, id, dispatch]);

  useEffect(() => {
    // при изменении дат отменяет все изменения
    dispatch(setEditRooms(deleteRoomFromEdited(editRooms, id)));

    dispatch(resetUpdatedCells());
    dispatch(resetSubmitCells());
  }, [selectedDates, dispatch]);

  useEffect(() => {
    // Проверяет кол-во комнат на каждую дату и добавляет или убирает в массив недоступных дат
    const previousNotAvailableDates = toValueOrEmptyArray(
      storeNotAvailableDates?.[id],
    );

    const newNotAvailableDates = buildNewNotAvailableDates(
      previousNotAvailableDates,
      daysDates,
      roomConstraints,
      dateAvailabilities,
    );

    dispatch(
      setNotAvailableDates({
        ...storeNotAvailableDates,
        [id]: newNotAvailableDates,
      }),
    );
  }, [roomConstraints, daysDates, id]);

  useEffect(() => {
    // если есть изменения, показываем кнопки сохранения/отмены
    if (submitCells && submitCells[id]?.length > 0) {
      // Берется последнее значение editRooms
      const {editRooms} = store.getState().calendar;

      dispatch(setEditRooms({...editRooms, [id]: true}));
    }
  }, [submitCells, id]);

  useEffect(() => {
    // проверяет есть ли изменения в закрытиях номеров
    const isRoomsConstraintsChanged = !equals(
      roomConstraints,
      initialRoomConstraints,
    );

    if (isRoomsConstraintsChanged) {
      dispatch(setEditRooms({...editRooms, [id]: true}));
    } else {
      dispatch(setEditRooms(deleteRoomFromEdited(editRooms, id)));
    }
  }, [roomConstraints, initialRoomConstraints]);

  useEffect(() => {
    // Получение первоначальных данных при выборе дат в дата пикере, а также открытии номера
    const getCalendarData = async (hotelId: number) => {
      return fetchCalendarData(
        hotelId,
        id,
        selectedDates.from,
        selectedDates.to,
        onCalendarDataComplete,
      );
    };

    if (hotel?.id && isOpen && !loading) {
      dispatch(setIsLoading(true));

      getCalendarData(hotel.id).finally(() => {
        dispatch(setIsLoading(false));
      });
    }
  }, [hotel, id, selectedDates, isOpen]);

  useEffect(() => {
    // Обновление всех данных по таймеру
    const getCalendarData = async (hotelId: number) => {
      return fetchCalendarData(
        hotelId,
        id,
        selectedDates.from,
        selectedDates.to,
        onCalendarDataComplete,
      );
    };

    if (isTimerUpdate && hotel?.id && isOpen) {
      dispatch(setIsLoading(true));

      getCalendarData(hotel.id).finally(() => {
        dispatch(setIsLoading(false));
        setIsTimerUpdate(false);
      });
    }
  }, [isTimerUpdate, hotel, isOpen, id, selectedDates]);

  useEffect(() => {
    // Подгрузка данных при горизонтальном скролле вправо (будущие даты)
    const isNoEditedRooms = checkIsEmptyObject(editRooms);
    const {newFrom, newTo, isLimitExceeded, preventInitialLoad} =
      buildNewDatesOnScroll(
        selectedDates.from,
        selectedDates.to,
        HORIZONTAL_INFINITE_SCROLL_STEP,
        ScrollDirectionType.RIGHT,
      );

    // не подгружать данные если уже идет загрузка/есть редактирования/уже макс. возможная дата
    if (
      !isNeedLoadMoreDates ||
      loading ||
      !isNoEditedRooms ||
      isDatesLimitExceeded ||
      preventInitialLoad
    ) {
      if (preventInitialLoad && !isDatesLimitExceeded) {
        dispatch(setIsDatesLimitExceeded(true));
      }

      return;
    }

    dispatch(setIsLoading(true));

    const getMoreCalendarData = async (hotelId: number) => {
      return fetchCalendarData(
        hotelId,
        id,
        newFrom,
        newTo,
        onInfiniteScrollLoadComplete,
      );
    };
    dispatch(
      setSelectedDates({
        from: getSlashDateFormat(newFrom),
        to: getSlashDateFormat(newTo),
      }),
    );

    if (!isDatesLimitExceeded) {
      dispatch(setIsDatesLimitExceeded(isLimitExceeded));
    }

    if (hotel?.id && isOpen) {
      dispatch(setIsLoading(true));

      getMoreCalendarData(hotel.id).finally(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsNeedLoadMoreDates(false));
      });
    }
  }, [isNeedLoadMoreDates]);

  useEffect(() => {
    // Подгрузка данных при горизонтальном скролле влево (предыдущие даты)
    const isNoEditedRooms = checkIsEmptyObject(editRooms);
    const {newFrom, newTo, isLimitExceeded, preventInitialLoad} =
      buildNewDatesOnScroll(
        selectedDates.from,
        selectedDates.to,
        HORIZONTAL_INFINITE_SCROLL_STEP,
        ScrollDirectionType.LEFT,
      );

    // не подгружать данные если уже идет загрузка/есть редактирования/уже макс. возможная дата
    if (
      !isNeedLoadBeforeDates ||
      loading ||
      !isNoEditedRooms ||
      isBeforeDatesLimitExceeded ||
      preventInitialLoad
    ) {
      if (preventInitialLoad && !isBeforeDatesLimitExceeded) {
        dispatch(setIsBeforeDatesLimitExceeded(true));
      }

      return;
    }

    dispatch(setIsLoading(true));

    const getMoreCalendarData = async (hotelId: number) => {
      return fetchCalendarData(
        hotelId,
        id,
        newFrom,
        newTo,
        onInfiniteScrollLoadComplete,
      );
    };
    dispatch(
      setSelectedDates({
        from: getSlashDateFormat(newFrom),
        to: getSlashDateFormat(newTo),
      }),
    );

    if (!isBeforeDatesLimitExceeded) {
      dispatch(setIsBeforeDatesLimitExceeded(isLimitExceeded));
    }

    if (hotel?.id && isOpen) {
      dispatch(setIsLoading(true));

      getMoreCalendarData(hotel.id).finally(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsNeedLoadBeforeDates(false));
      });
    }
  }, [isNeedLoadBeforeDates]);

  useEffect(() => {
    // Если from === сегодня или to === максимальная дата, не производить проверку и не загружать даты
    const maxEndDate = addCurrentYearDays(today);
    const toDate = parseRangeDateToBackendDateFormat(selectedDates.to);
    const fromDate = parseRangeDateToBackendDateFormat(selectedDates.from);

    if (isSameDay(toDate, maxEndDate)) {
      dispatch(setIsDatesLimitExceeded(true));
    }

    if (isSameDay(fromDate, today)) {
      dispatch(setIsBeforeDatesLimitExceeded(true));
    }
  }, [selectedDates]);

  useEffect(() => {
    if (isEdit) {
      showWarnMessage(t('calendar:notify.new_changes'));
    }
  }, [isEdit]);

  useEffect(() => {
    if (openedRooms[id]) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      dispatch(resetRoomDataById(id));
    }
  }, [openedRooms[id], id]);

  useEffect(() => {
    // при открытии фильтр в модалке масс эдита будет равен последнему открытому руму
    if (isOpen) {
      dispatch(
        setMassEditModalFilters({
          ...massEditModalFilters,
          roomId: id,
        }),
      );

      dispatch(setOpenedRooms({...openedRooms, [id]: true}));
      return;
    }

    const newOpenedRooms = deleteRoomFromEdited(openedRooms, id);
    dispatch(setOpenedRooms(newOpenedRooms));
  }, [isOpen, id]);

  useEffect(() => {
    // При изменении свитчеров показывать выключенные тарифы/включить таймер отключает стейт загрузки
    const timeout = setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isShowDisabledRates, isTimerEnabled, dispatch]);

  useEffect(() => {
    if (isOpen) {
      setInitialScrollsPosition(calendarRef);
    }
  }, [isOpen]);

  return (
    <ArrowText
      parentState={isOpen}
      parentHandler={(value) => setIsOpen(value)}
      title={name}
      onPreventOpen={onOpenRoomWarning}
      preventOpen={openedRoomsArray.length >= ALLOWED_OPENED_ROOMS_COUNT}
      size="boldS"
      subtitle={t('calendar:id', {id})}
      subtitleSize="boldXS"
      disabled={loading}
      buttons={
        isEdit ? (
          <ActionButtons
            isEdit={isEdit}
            loading={loading}
            onSave={handleSaveChanges}
            onCancel={handleCancelChanges}
            isSmall
            type="save_changes"
          />
        ) : (
          isTimerEnabled && (
            <UpdateTimer
              setIsTimerUpdate={setIsTimerUpdate}
              isTimerUpdate={isTimerUpdate}
              roomId={id}
              isEdit={isEdit}
              roomIndex={roomIndex}
            />
          )
        )
      }
      fixedChildren={
        <RoomsRow>
          <FirstDataCell mobile={mobile}>
            {mobile ? (
              <MobileCalendarTooltip
                trigger={
                  <IconWrapper>
                    <RoomIcon color={theme.palette.fontPrimary} />
                  </IconWrapper>
                }
              >
                {t('calendar:tooltip.rooms_count')}
              </MobileCalendarTooltip>
            ) : (
              <FirstDataCellText
                size="XS"
                selected={isRowSelected(id, 'rooms_count', selectedCell)}
              >
                {t(
                  hasRealtyIntegration
                    ? 'calendar:rooms_free'
                    : 'calendar:rooms_count',
                )}
              </FirstDataCellText>
            )}
          </FirstDataCell>
          <RightRow
            className={SCROLL_CLASSNAME}
            onScroll={handleInfiniteScroll}
            isTouch={isTouch}
          >
            {daysDates?.map((dayDate, index) => {
              const customRoomsAvailability = buildCustomRoomAvailabilityCount(
                roomConstraints,
                dayDate.date,
              );

              const {
                availableRoomsNumber,
                isChanged,
                hasFree,
                maxAvailableRoomsAvailabilities,
                computedRoomsAvailability,
              } = buildAvailableRoomsNumber(
                roomConstraints,
                dateAvailabilities,
                dayDate.date,
                customRoomsAvailability,
              );
              const availableRoomNumberOutput = Math.max(
                availableRoomsNumber,
                0,
              );

              const outputValue =
                computedRoomsAvailability >= 0
                  ? computedRoomsAvailability
                  : availableRoomNumberOutput;

              return (
                // Изменение кол-ва доступных номеров
                <RoomAvailabilitiesCell
                  key={dayDate.id}
                  changed={isChanged}
                  disabled={
                    hasBnovoIntegration ||
                    hasHotelPmsIntegration ||
                    hasRealtyIntegration
                  }
                  availableRoomsNumber={availableRoomsNumber}
                  customRoomsAvailability={customRoomsAvailability}
                  selected={isCellSelected(
                    id,
                    index,
                    'rooms_count',
                    selectedCell,
                  )}
                  onDatesAvailabilitiesClick={onRoomDataCellClick}
                  outputValue={outputValue}
                  hasFree={hasFree}
                  id={id}
                  index={index}
                  dayDate={dayDate}
                  roomsNumberId={roomsNumberId}
                  onRoomsAvailabilitiesPopupClose={
                    handleDatesAvailabilitiesPopupClose
                  }
                  onRoomsAvailabilitiesPopupApply={handleChangeRoomsNumber}
                  maxFreeRooms={maxAvailableRoomsAvailabilities}
                />
              );
            })}
          </RightRow>
        </RoomsRow>
      }
    >
      <Wrapper>
        <Loader loading={loading} />

        <div>
          {roomConstraints.map(
            (item: ClientCalendarRoomConstraints[number], index: number) => {
              const {beforeWidth, afterWidth, width} =
                createRoomContraintsProperties(item, daysDates);

              const rowKey =
                item.id ||
                `${item.from}_${item.to}_${index}_${item.type}_${id}`;

              if (item.is_delete || isRoomAvailabilityConstraint(item)) {
                return null;
              }

              return (
                <RoomsRow key={rowKey}>
                  <FirstDataCell mobile={mobile}>
                    {index === 0 ? (
                      mobile ? (
                        <MobileCalendarTooltip
                          trigger={
                            <IconWrapper>
                              <CloseIcon />
                            </IconWrapper>
                          }
                        >
                          {t('calendar:tooltip.closes')}
                        </MobileCalendarTooltip>
                      ) : (
                        <FirstDataCellText size="XS">
                          {t('calendar:closes')}
                        </FirstDataCellText>
                      )
                    ) : null}
                  </FirstDataCell>
                  <RightRow
                    height={29}
                    className={SCROLL_CLASSNAME}
                    onScroll={handleHeaderScroll}
                    noGap
                    isTouch={isTouch}
                  >
                    <EmptyCell minWidth={beforeWidth} />
                    <RoomConstraint
                      width={width}
                      constraint={item}
                      roomId={id}
                    />
                    <EmptyCell minWidth={afterWidth} />
                  </RightRow>
                </RoomsRow>
              );
            },
          )}
        </div>

        {ratesPrices?.map(
          ({
            rate,
            by_guests_number,
            cancellation_fine,
            hotelier_constraints,
            bnovo_constraints,
            realty_constraints,
          }) => {
            // TODO: Добавить hotel_pms_constraints
            const {enabled, period} = rate;
            const stay = hotelier_constraints?.stay;
            const {from, to} = period;
            const hasPeriod =
              (!isNil(from) || !isNil(to)) &&
              !hasBnovoIntegration &&
              !hasHotelPmsIntegration;

            if (!isShowDisabledRates && !enabled) {
              return null;
            }

            return (
              <div key={rate.id}>
                <Text size="boldXS">{rate.name}</Text>
                {hasPeriod && (
                  <RateDurationRow>
                    <RateDurationTitle size="XS">
                      {t('calendar:rate_duration_period')}
                    </RateDurationTitle>

                    {from && (
                      <SubText size="XS">
                        {t('calendar:rate_duration_from', {
                          date: getViewMonthsNameFromBackendDate(from),
                        })}
                      </SubText>
                    )}
                    {to && (
                      <SubText size="XS">
                        {t('calendar:rate_duration_to', {
                          date: getViewMonthsNameFromBackendDate(to),
                        })}
                      </SubText>
                    )}
                  </RateDurationRow>
                )}

                <RestrictionsRow>
                  <FirstDataCell mobile={mobile}>
                    {mobile ? (
                      <MobileCalendarTooltip
                        trigger={
                          <IconWrapper>
                            <ExclamationMarkIcon
                              color={theme.palette.fontPrimary}
                            />
                          </IconWrapper>
                        }
                      >
                        {t('calendar:tooltip.restrictions')}
                      </MobileCalendarTooltip>
                    ) : (
                      <FirstDataCellText
                        size="XS"
                        selected={isRateRowSelected(
                          id,
                          'restrictions',
                          rate.id,
                          selectedCell,
                        )}
                      >
                        {t('calendar:restrictions')}
                      </FirstDataCellText>
                    )}
                  </FirstDataCell>
                  <RightRow
                    className={SCROLL_CLASSNAME}
                    onScroll={handleHeaderScroll}
                    isTouch={isTouch}
                  >
                    {daysDates?.map((dayDate) => {
                      const {hasConstraint} = isDateInConstrainStayPeriod(
                        stay,
                        new Date(dayDate.date),
                      );

                      const currentBnovoConstraint = getBnovoConstraint(
                        hasBnovoIntegration,
                        bnovo_constraints,
                        dayDate.date,
                      );

                      const currentRealtyConstraint = getRealtyConstraint(
                        hasRealtyIntegration,
                        realty_constraints,
                        dayDate.date,
                      );

                      return (
                        <RestrictionCell
                          key={dayDate.id}
                          hasConstraint={hasConstraint}
                          stay={stay}
                          rate={rate}
                          dayDate={dayDate.date}
                          constraints={hotelier_constraints}
                          bnovoConstraint={currentBnovoConstraint}
                          realtyConstraint={currentRealtyConstraint}
                        />
                      );
                    })}
                  </RightRow>
                </RestrictionsRow>
                {by_guests_number?.map((byGuestsNumber) => {
                  return (
                    <PricesRow key={byGuestsNumber.guests_number}>
                      <FirstDataCell mobile={mobile}>
                        <HumanWrapper mobile={mobile}>
                          <SubText size="XS">{`${byGuestsNumber.guests_number}${HUMAN_COUNTER_DIVIDER}`}</SubText>
                          <Human />
                        </HumanWrapper>
                      </FirstDataCell>
                      <RoomPrice
                        stay={stay}
                        prices={byGuestsNumber.prices}
                        room={id}
                        selectedCell={selectedCell}
                        guestNumber={byGuestsNumber.guests_number}
                        rateId={rate.id}
                        isRateDisabled={!enabled}
                        selectedRange={selectedRange}
                      />
                    </PricesRow>
                  );
                })}
                <CancellationRow>
                  <FirstDataCell mobile={mobile}>
                    {mobile ? (
                      <MobileCalendarTooltip
                        trigger={
                          <IconWrapper>
                            <CancelIcon color={theme.palette.fontPrimary} />
                          </IconWrapper>
                        }
                      >
                        {t('calendar:tooltip.cancellation')}
                      </MobileCalendarTooltip>
                    ) : (
                      <FirstDataCellText
                        size="XS"
                        selected={isRateRowSelected(
                          id,
                          'cancellation',
                          rate.id,
                          selectedCell,
                        )}
                      >
                        {t('calendar:cancellation')}
                      </FirstDataCellText>
                    )}
                  </FirstDataCell>
                  <RightRow
                    className={SCROLL_CLASSNAME}
                    onScroll={handleHeaderScroll}
                    isTouch={isTouch}
                  >
                    {daysDates?.map((dayDate) => {
                      return (
                        <CancellationCell
                          key={dayDate.id}
                          cancellation_fine={cancellation_fine}
                          dayDate={dayDate}
                        />
                      );
                    })}
                  </RightRow>
                </CancellationRow>
              </div>
            );
          },
        )}
      </Wrapper>
    </ArrowText>
  );
};
