import {useRouter} from 'next/router';
import { isEmpty, isNil } from 'ramda'
import {createContext, FC, useEffect, useState} from 'react';
import {
  CookieNames,
  deleteCookie,
  getCookie,
} from 'source/utilities/network/cookies';

export type AuthorizationState = State<boolean>;

const initial = false;

export const AuthorizationContext = createContext<AuthorizationState>([
  initial,
  () => {},
]);

export const AuthorizationProvider: FC<
  ProviderProperties<AuthorizationState>
> = ({serverValue: serverAuthorizationValue, ...properties}) => {
  const router = useRouter();
  const state = useState<ContextValue<AuthorizationState>>(
    serverAuthorizationValue ?? initial,
  );
  const [authorization, setAuthorization] = state;

  useEffect(() => {
    if (serverAuthorizationValue === false && !authorization) {
      deleteCookie(CookieNames.HOTELIER_AUTHORIZATION);
      deleteCookie(CookieNames.TEMPORARY_AUTHORIZATION);
    }
  }, [serverAuthorizationValue, state]);

  useEffect(() => {
    const newAuthorization =
      serverAuthorizationValue ??
      !isNil(getCookie(CookieNames.HOTELIER_AUTHORIZATION)) ??
      initial;
    setAuthorization(newAuthorization);
  }, [router.pathname]);

  return <AuthorizationContext.Provider value={state} {...properties} />;
};
