import {Option} from 'library/components/select';
import {Translate} from 'next-translate';
import {Theme} from '@emotion/react';
import {
  EIntegrations,
  EIntergrationsLabels,
  ERealtyIntegrationStatuses,
} from './types';
import {ADD_NEW_ROOM_OPTION} from './constants';

export const findCopyableRoomFromEnabledIntegrations = (
  enabledIntegrations: RealtyIntegration[],
  copyableRoom: CopyableIntegrationRooms[number],
): RealtyIntegration | null => {
  if (enabledIntegrations.length === 0) {
    return null;
  }

  const currentIntegrationAccomodation = enabledIntegrations.find(
    (enabledIntegration) => enabledIntegration.id === copyableRoom.id,
  );

  return currentIntegrationAccomodation || null;
};

export const getRealtyIntegrationStatus = (
  integration: RealtyIntegration,
  theme: Theme,
): {status: ERealtyIntegrationStatuses; color: string} => {
  const {accommodation_id, is_ready, is_configured, is_deleted} = integration;

  switch (true) {
    case is_deleted: {
      return {
        status: ERealtyIntegrationStatuses.DELETED,
        color: theme.palette.danger,
      };
    }
    case !accommodation_id: {
      return {
        status: ERealtyIntegrationStatuses.DISABLED,
        color: theme.palette.primaryCircuit,
      };
    }
    case is_ready: {
      return {
        status: ERealtyIntegrationStatuses.ENABLED,
        color: theme.palette.success,
      };
    }
    case is_configured: {
      return {
        status: ERealtyIntegrationStatuses.IN_PROCESS,
        color: theme.palette.wating,
      };
    }
    default: {
      return {
        status: ERealtyIntegrationStatuses.DISABLED,
        color: theme.palette.matterhorn,
      };
    }
  }
};

export const buildCopyableRoomsOptions = (
  copyableRooms: CopyableIntegrationRooms,
  t: Translate,
): Option[] => {
  const defaultOption = {
    ...ADD_NEW_ROOM_OPTION,
    label: t(ADD_NEW_ROOM_OPTION.label),
  };

  if (!copyableRooms || copyableRooms.length === 0) {
    return [defaultOption];
  }

  const copyableRoomsOptions = copyableRooms.map((room) => ({
    label: room.name,
    value: room.id,
  }));

  return [defaultOption, ...copyableRoomsOptions];
};

export const getIntegrationOptionByValue = (
  options: Option[],
  targetValue: EIntegrations,
) => {
  const appropriateOption = options.find(({value}) => value === targetValue);

  return appropriateOption || options[0];
};

export const getChannelManagersOptions = (
  isTravelLineIntegration?: boolean,
): Option<EIntegrations>[] => {
  const options = [
    {
      label: EIntergrationsLabels.BNOVO,
      value: EIntegrations.BNOVO,
    },
    {
      label: EIntergrationsLabels.REALTY,
      value: EIntegrations.REALTY,
    },
    // TODO: Раскомментить после релиза
    // {
    //   label: EIntergrationsLabels.HOTEL_PMS,
    //   value: EIntegrations.HOTEL_PMS,
    // },
  ];

  if (isTravelLineIntegration) {
    options.push({
      label: EIntergrationsLabels.TRAVEL_LINE,
      value: EIntegrations.TRAVEL_LINE,
    });
  }

  return options;
};
