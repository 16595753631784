import {fingerPrintExchange} from 'library/api/exchanges/finger-print'
import type {RequestExchange} from 'requestify-lite/dist/rest/request/exchanges/request'

export const getRequestExchange: RequestExchange = async (init) => {
  return {
    ...init,
    headers: {
      ...init.headers,
      ...(await fingerPrintExchange()),
    },
  }
}
