import {Option} from 'library/components/select';
import {Translate} from 'next-translate';
import {equals} from 'ramda';

import {showWarnMessage} from 'source/utilities/exceptions/business';
import {store} from 'source/store';

import type {
  CheckInEnd,
  CheckInStart,
  CheckOutEnd,
  CheckOutStart,
  PlacementDescription,
  PoliciesDirectories,
} from 'slices/policies';
import {
  DepositPaymentType,
  EAccessWithAnimalType,
  ECalculationPoliciesType,
  PetsAllowedDescription,
  PetsType,
  TaxesAndFeesCalculation,
  TaxesAndFeesType,
} from 'slices/policies';
import {
  DEFAULT_DESCRIPTION_VALUE,
  END_TIME_OPTIONS,
  initialPoliciesState,
  START_TIME_OPTIONS,
} from 'slices/policies/lib/mocks';
import {RequiredTabsType} from 'slices/policies/lib/types';
import {
  setDepositPaymentType,
  setIsNeedSaving,
  setPetsType,
  setPlacementTime,
  setPoliciesTaxes,
} from 'slices/policies/store';
import {isBefore, parse} from 'date-fns';
import {
  END_OF_THE_DAY,
  INITIAL_END_TIME_OPTION_INDEX,
  INITIAL_START_TIME_OPTION_INDEX,
  POLICIES_BACKEND_FORMAT,
} from './constants';

export const isPlacementDescriptionValueExist = (
  description?: PlacementDescription,
): boolean => {
  if (!description) {
    return false;
  }

  return description.some(
    (description) => description.value !== DEFAULT_DESCRIPTION_VALUE,
  );
};

const START_TIME = START_TIME_OPTIONS[INITIAL_START_TIME_OPTION_INDEX];
const END_TIME = END_TIME_OPTIONS[INITIAL_END_TIME_OPTION_INDEX];

export const getCheckInStartOption = (checkInStart?: CheckInStart): Option => {
  if (!checkInStart) {
    return START_TIME;
  }

  return {
    value: checkInStart,
    label: checkInStart,
  };
};

export const getCheckInEndOption = (checkInEnd?: CheckInEnd): Option => {
  if (!checkInEnd) {
    return END_TIME;
  }

  return {
    value: checkInEnd,
    label: checkInEnd,
  };
};

export const getCheckOutStartOption = (
  checkOutStart?: CheckOutStart,
): Option => {
  if (!checkOutStart) {
    return START_TIME;
  }

  return {
    value: checkOutStart,
    label: checkOutStart,
  };
};

export const getCheckOutEndOption = (checkOutEnd?: CheckOutEnd): Option => {
  if (!checkOutEnd) {
    return END_TIME;
  }

  return {
    value: checkOutEnd,
    label: checkOutEnd,
  };
};

export const isExistExitAroundClock = (
  checkInEnd?: CheckInEnd,
  checkOutStart?: CheckOutStart,
): boolean => {
  if (!checkInEnd || !checkOutStart) {
    return false;
  }
  return checkInEnd !== '' && checkOutStart !== '';
};

export const getPetsPlacementTypeOption = (
  directoriesPetsType: PetsType[],
  petsType?: PetsType,
): Option => {
  if (!petsType || petsType.value === -1 || !petsType.title) {
    const directory = directoriesPetsType[0];
    return {
      value: directory ? directory?.value : EAccessWithAnimalType.IMPOSSIBLE,
      label: directory
        ? directory?.title
        : 'policies:with-animals.status-select.impossible',
    };
  }

  return {
    value: petsType.value,
    label: petsType.title,
  };
};

export const getPetsDirectoriesTypeOptions = (
  pets?: PoliciesDirectories['petsAllowedType'],
): Option[] => {
  if (!pets) {
    return [];
  }

  return pets.map((pet) => {
    return {
      value: pet.value,
      label: pet.title,
    };
  });
};

export const isPetsDescriptionValueExist = (
  description?: PetsAllowedDescription,
): boolean => {
  if (!description) {
    return false;
  }

  return description.some(
    (description) => description.value !== DEFAULT_DESCRIPTION_VALUE,
  );
};

export const getDepositPaymentTypeOptions = (
  methodsDirectories?: PoliciesDirectories['depositPaymentTypes'],
): Option[] => {
  if (!methodsDirectories) {
    return [];
  }

  return methodsDirectories.map((method) => {
    return {
      label: method.title,
      value: method.value,
    };
  });
};

export const getDepositPaymentTypeOption = (
  methodsDirectories: DepositPaymentType[],
  method?: DepositPaymentType,
  onDefaultPaymentMethod?: (type: Option) => void,
): Option => {
  if (!method || method?.value === -1 || !method?.title) {
    const directory = methodsDirectories[0];

    const defaultPaymentType = {
      value: directory ? directory?.value : -1,
      label: directory ? directory?.title : '',
    };

    onDefaultPaymentMethod?.(defaultPaymentType);

    return defaultPaymentType;
  }

  return {
    label: method.title,
    value: method.value,
  };
};

export const getTaxedAndFeesTypeOptions = (
  taxesDirectories?: PoliciesDirectories['taxesAndFees'],
): Option[] => {
  if (!taxesDirectories) {
    return [];
  }

  return taxesDirectories.map((tax) => {
    return {
      label: tax.title,
      value: tax.value,
    };
  });
};

export const getTaxedAndFeesTypeOption = (
  taxesDirectories: TaxesAndFeesType[],
  taxes?: TaxesAndFeesType,
): Option => {
  if (!taxes || taxes.value === -1 || !taxes.title) {
    const directory = taxesDirectories[0];
    return {
      value: directory ? directory?.value : -1,
      label: directory ? directory?.title : '',
    };
  }

  return {
    label: taxes.title,
    value: taxes.value,
  };
};

export const getTaxedAndFeesCalculationOption = (
  taxesCalculationDirectories: TaxesAndFeesCalculation[],
  taxesCalculation?: TaxesAndFeesCalculation,
): Option => {
  if (
    !taxesCalculation ||
    taxesCalculation.value === -1 ||
    !taxesCalculation.title
  ) {
    const directory = taxesCalculationDirectories[0];
    return {
      value: directory ? directory?.value : -1,
      label: directory ? directory?.title : '',
    };
  }

  return {
    label: taxesCalculation.title,
    value: taxesCalculation.value,
  };
};

export const getTaxedAndFeesCalculationOptions = (
  taxedCalculationDirectories?: PoliciesDirectories['taxesAndFeesCalculating'],
): Option[] => {
  if (!taxedCalculationDirectories) {
    return [];
  }

  return taxedCalculationDirectories.map((calculation) => {
    return {
      label: calculation.title,
      value: calculation.value,
    };
  });
};

export const setPoliciesTemplateValues = () => {
  const {policies, directories} = store.getState().policies;
  const {pets_allowed, taxes_and_fees, security_deposit, placement_time} =
    policies;
  const placementType = getPetsPlacementTypeOption(
    directories?.petsAllowedType ?? [],
    pets_allowed?.type,
  );
  store.dispatch(setPetsType(placementType));

  const depositPaymentType = getDepositPaymentTypeOption(
    directories.depositPaymentTypes ?? [],
    security_deposit?.type,
  );
  store.dispatch(setDepositPaymentType(depositPaymentType));

  const taxes = taxes_and_fees.map((tax) => {
    const taxesType = getTaxedAndFeesTypeOption(
      directories?.taxesAndFees || [],
      tax.tax_and_fee_type,
    );
    const calculation = getTaxedAndFeesCalculationOption(
      directories?.taxesAndFeesCalculating || [],
      tax.calculation_type,
    );
    return {
      ...tax,
      tax_and_fee_type: {
        value: taxesType.value,
        title: taxesType.label,
      },
      calculation_type: {
        value: calculation.value,
        title: calculation.label,
      },
    };
  });

  store.dispatch(setPoliciesTaxes([...taxes]));

  // если нужно что бы по дефолту круглосуточно стояло, коммент убрать с двух полей
  const time = {
    checkin_start: getCheckInStartOption(placement_time?.checkin_start),
    checkin_end: getCheckInEndOption(placement_time?.checkin_end),
    checkout_start: getCheckOutStartOption(placement_time?.checkout_start),
    checkout_end: getCheckOutEndOption(placement_time?.checkout_end),
  };

  Object.entries(time).map((entries) => {
    const [key, item] = entries;
    store.dispatch(setPlacementTime({key, value: item.value}));
  });
};

let currentValue: Policies;

export const subscribeUpdatePoliciesObject = (translate: Translate) => {
  const previousValue = currentValue;
  const {policies, isNeedCreatePolicies, isNeedSaving, isInitialized} =
    store.getState().policies;
  currentValue = policies;

  if (
    !previousValue ||
    !currentValue ||
    !Array.isArray(Object.keys(currentValue)) ||
    Object.keys(currentValue).length === 0
  ) {
    return;
  }

  const isRatesUpdated = !equals(previousValue, currentValue);

  if (isRatesUpdated && !isNeedCreatePolicies) {
    store.dispatch(setIsNeedSaving(isRatesUpdated));

    if (isRatesUpdated !== isNeedSaving && isInitialized) {
      showWarnMessage(
        `${translate('headline') as string}: ${
          translate('notify.new_changes') as string
        }`,
      );
    }
  }
};

export const removeEmptyValuesInDescription = (
  description: PlacementDescription,
) =>
  description?.filter(
    (desc) => desc.value !== '' && desc.value !== DEFAULT_DESCRIPTION_VALUE,
  );

export const buildPlacementDescription = (
  placementDescriptions: PlacementDescription,
) => {
  return initialPoliciesState.placement_time?.description?.map((desc) => ({
    ...desc,
    value:
      placementDescriptions?.find(
        (placementDescription) => placementDescription?.locale === desc.locale,
      )?.value ?? DEFAULT_DESCRIPTION_VALUE,
  }));
};

export const buildPetsAllowedDescription = (
  petsDescriptions: PetsAllowedDescription,
) => {
  return initialPoliciesState.pets_allowed?.description?.map((desc) => ({
    ...desc,
    value:
      petsDescriptions?.find(
        (petsDescription) => petsDescription?.locale === desc.locale,
      )?.value ?? DEFAULT_DESCRIPTION_VALUE,
  }));
};

export const isCalculationTypeExistCheckDays = (type: number) =>
  [
    ECalculationPoliciesType.PerPersonPerDay,
    ECalculationPoliciesType.PerDayOfStay,
  ].includes(type);

export const checkIsShouldOpenTab = (
  isNeedToCheck: boolean,
  key: RequiredTabsType,
  tabs: RequiredTabsType[],
) => isNeedToCheck && !tabs.includes(key);

export const parseCheckinEndTime = (
  options: Option[],
  checkInTime: CheckOutStart,
): Option[] => {
  if (!checkInTime) {
    return options;
  }

  const checkInDate = parse(checkInTime, POLICIES_BACKEND_FORMAT, new Date());

  return options.filter((option) => {
    if (option.value === END_OF_THE_DAY) {
      return true;
    }

    const optionDate = parse(option.value, POLICIES_BACKEND_FORMAT, new Date());

    return !isBefore(optionDate, checkInDate);
  });
};

export const parseCheckoutEndTime = (
  options: Option[],
  checkInTime: CheckOutStart,
  isAroundClock: boolean,
): Option[] => {
  if (!checkInTime) {
    return options;
  }

  if (isAroundClock) {
    return options;
  }

  const checkInDate = parse(checkInTime, POLICIES_BACKEND_FORMAT, new Date());

  return options.filter((option) => {
    if (option.value === END_OF_THE_DAY) {
      return true;
    }

    const optionDate = parse(option.value, POLICIES_BACKEND_FORMAT, new Date());

    return !isBefore(optionDate, checkInDate);
  });
};
