export const MAX_HOTEL_NAME_LENGTH = 255;
export const MAX_EMAIL_LENGTH = 255;
export const MAX_ADDRESS_LENGTH = 255;
export const MAX_PHONE_LENGTH = 15;

export const MIN_MODERATION_DESCRIPTION_VALUE = 255;
export const MAX_MODERATION_DESCRIPTION_VALUE = 5000;

export const DEFAULT_HOTEL_MODERATION_LAST: HotelModeraionLast = {
  id: -1,
  status: {
    value: 1,
    title: '',
  },
  admin_comment: null,
  requested_at: '',
  moderated_at: null,
};

export const EMPTY_DESCRIPTION_INPUT_VALUE = '<p><br></p>';
