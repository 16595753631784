import {isNil} from 'ramda';
import {useContext} from 'react';
import {HotelContext} from 'source/context/hotel';

type HotelPmsIntegrationHookReturn = [
  boolean,
  Partial<Hotel['hotel_pms_integration']>,
];

/**
 * Возвращает интегрирован ли с HotelPMS отель, и объект с данными
 * @return [hasIntegration, Hotel["hotel_pms_integration"]]
 */
export const useHotelPmsIntegration = (): HotelPmsIntegrationHookReturn => {
  const [hotel] = useContext(HotelContext);

  if (!hotel) {
    return [false, undefined];
  }

  const hasIntegration =
    hotel &&
    'hotel_pms_integration' in hotel &&
    !isNil(hotel.hotel_pms_integration) &&
    hotel.hotel_pms_integration.is_configured;

  return [hasIntegration, hotel?.hotel_pms_integration];
};
