import {request} from 'library/api';
import {__, assoc, path, unless} from 'ramda';
import {Utilities} from 'source/context/utilities';
import {AuthMeListResponse} from 'types/api-scheme';
import {incorrectUser} from '../exceptions/business';
import {isUser} from '../guards/business';
import {url} from '../network/url';

export const checkUser = async (
  utilities: Utilities,
  headers: Headers,
): Promise<any> => {
  const isAuthorizationHeadersExist =
    headers.has('authorization') || headers.has('x-access-token');

  if (!isAuthorizationHeadersExist) {
    return Promise.reject().catch(() => ({user: null}));
  }

  return request<AuthMeListResponse>(url('auth/me'), {
    method: 'GET',
    headers,
  })
    .then(path<AuthMeListResponse['data']>([1, 'data']))
    .then(unless(isUser, incorrectUser))
    .then(assoc('user', __, utilities))
    .then(assoc('authorized', true));
};
