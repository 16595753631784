import ProcessEnv = NodeJS.ProcessEnv;

export const isServer = () => typeof window === 'undefined';

export const isClient = () => typeof window !== 'undefined';

const getEnvironment = (
  key: keyof ProcessEnv,
): ProcessEnv[keyof ProcessEnv] => {
  const environment = process.env;

  if (!(key in environment)) {
    throw new Error(`Environment: ${key} is not defined in .env`);
  }

  return environment[key];
};

export const YANDEX_API_KEY = getEnvironment('NEXT_PUBLIC_YMAPS_KEY');
export const ZHILIBYLI_URL = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_URL'),
);
export const IS_LOCALHOST = JSON.parse(
  getEnvironment('NEXT_PUBLIC_IS_LOCALHOST') || '',
);
export const ZHILIBYLI_HELP_URL = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_HELP_URL'),
);
export const HOTEL_PMS_LANDING_URL = String(
  getEnvironment('NEXT_PUBLIC_HOTEL_PMS_LANDING_URL'),
);
export const HOSTNAME = getEnvironment('NEXT_PUBLIC_HOSTNAME') || '';
export const ZHILIBYLI_PHONE = getEnvironment('NEXT_PUBLIC_ZHILIBYLI_PHONE');
export const HOTELIERS_KNOWLEDGE_BASE_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_HOTELIERS_KNOWLEDGE_BASE_ROUTE'),
);
export const ZHILIBYLI_EMAIL = getEnvironment('NEXT_PUBLIC_ZHILIBYLI_EMAIL');
export const WHATSAPP = String(getEnvironment('NEXT_PUBLIC_WHATSAPP'));
export const CONNECT_TRAVELLINE_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_CONNECT_TRAVELLINE_ROUTE'),
);
export const CONNECT_REALTY_CALENDAR_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_CONNECT_REALTY_CALENDAR_ROUTE'),
);
export const CONNECT_BNOVO_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_CONNECT_BNOVO_ROUTE'),
);
export const HOW_TO_BOOK_ARTICLE_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_HOW_TO_BOOK_ARTICLE_ROUTE'),
);
export const INFO_FOOTER = String(getEnvironment('NEXT_PUBLIC_INFO_FOOTER'));
export const KNOWLEDGE_BASE_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_KNOWLEDGE_BASE_ROUTE'),
);
export const YOUTUBE = String(getEnvironment('NEXT_PUBLIC_YOUTUBE'));
export const VK = String(getEnvironment('NEXT_PUBLIC_VK'));
export const TG = String(getEnvironment('NEXT_PUBLIC_TG'));
export const DZEN = String(getEnvironment('NEXT_PUBLIC_DZEN'));
export const ZHILIBYLI_ABOUT_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_ABOUT_ROUTE'),
);
export const ZHILIBYLI_MOBILE_APPS_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_MOBILE_APPS_ROUTE'),
);
export const ZHILIBYLI_OFFER_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_OFFER_ROUTE'),
);
export const ZHILIBYLI_SUPPORT_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_SUPPORT_ROUTE'),
);
export const ZHILIBYLI_POLITICS_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_POLITICS_ROUTE'),
);
export const ZHILIBYLI_RULES_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_RULES_ROUTE'),
);
export const ZHILIBYLI_SOFTWARE_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_SOFTWARE_ROUTE'),
);
export const ZHILIBYLI_PUBLIC_OFFER_AGREEMENT_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_PUBLIC_OFFER_AGREEMENT_ROUTE'),
);
export const ZHILIBYLI_AFFILIATE_ROUTE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_AFFILIATE_ROUTE'),
);
export const MAX_TOTAL_FILE_SIZE = getEnvironment(
  'NEXT_PUBLIC_MAX_TOTAL_FILE_SIZE',
);
export const BITRIX_SIDE_BUTTON_SCRIPT = getEnvironment(
  'NEXT_PUBLIC_BITRIX_SIDE_BUTTON_SCRIPT',
);
export const YM_ID = getEnvironment('NEXT_PUBLIC_YM_ID');
export const GOOGLE_TAG = getEnvironment('NEXT_PUBLIC_GOOGLE_TAG');

export const ROISTAT_ID = getEnvironment('NEXT_PUBLIC_ROISTAT_ID');

export const HOTJAR_ID = getEnvironment('NEXT_PUBLIC_HOTJAR_ID');

export const MIN_BALANCE_WITHDRAW_AMOUNT = getEnvironment(
  'NEXT_PUBLIC_MIN_BALANCE_WITHDRAW_AMOUNT',
);

export const MAX_BALANCE_WITHDRAW_AMOUNT = getEnvironment(
  'NEXT_PUBLIC_MAX_BALANCE_WITHDRAW_AMOUNT',
);
export const MAX_DESCRIPTION_VALUE_LENGTH = Number(
  getEnvironment('NEXT_PUBLIC_MAX_DESCRIPTION_VALUE_LENGTH'),
);

export const DISABLE_PROFILE_CODE_LENGTH = Number(
  getEnvironment('NEXT_PUBLIC_DISABLE_PROFILE_CODE_LENGTH'),
);

export const APPLICATION_PUSH_UUID_LOCAL_STORAGE_KEY =
  getEnvironment('NEXT_PUBLIC_APPLICATION_PUSH_UUID_LOCAL_STORAGE_KEY') || '';

export const APPLICATION_OS_TYPE_LOCAL_STORAGE_KEY =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_TYPE_LOCAL_STORAGE_KEY') || '';

export const APPLICATION_OS_VERSION_LOCAL_STORAGE_KEY =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_VERSION_LOCAL_STORAGE_KEY') || '';

export const APPLICATION_PUSH_UUID_HEADER_NAME =
  getEnvironment('NEXT_PUBLIC_APPLICATION_PUSH_UUID_HEADER_NAME') || '';

export const APPLICATION_OS_TYPE_HEADER_NAME =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_TYPE_HEADER_NAME') || '';

export const APPLICATION_OS_VERSION_HEADER_NAME =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_VERSION_HEADER_NAME') || '';

export const FINGERPRINT_COOKIE_KEY = getEnvironment(
  'NEXT_PUBLIC_FINGERPRINT_COOKIE_KEY',
) as string;

export const FINGERPRINT_PUBLIC_KEY = getEnvironment(
  'NEXT_PUBLIC_FINGERPRINT_PUBLIC_KEY',
) as string;

export const FINGERPRINT_MIN_VALUE_LENGTH = Number(
  getEnvironment('NEXT_PUBLIC_FINGERPRINT_MIN_VALUE_LENGTH'),
);
