import {getRequestExchange} from 'library/api/exchanges';
import {always, cond, T} from 'ramda';
import {request as libraryRequest} from 'requestify-lite';
import {isClient} from 'source/utilities/guards/environment';
import {clientHeaders, serverHeaders} from 'source/utilities/network/http';

type Options = Parameters<typeof libraryRequest>[0];

function parseHeaders(headers: unknown): Record<string, string> {
  if (headers instanceof Headers) {
    return Object.fromEntries(headers);
  }

  if (typeof headers !== 'object') {
    throw new TypeError('headers must be an object');
  }

  return headers as Record<string, string>;
}

export const request = async <T>(
  url: Options['url'],
  options: Options['options'],
  errorHandlers?: Options['errorHandlers'],
  exchanges?: Options['exchanges'],
) => {
  const spareHeaders = isClient() ? clientHeaders() : serverHeaders();

  return libraryRequest<T>({
    url,
    options: {
      ...options,
      headers: parseHeaders(options?.headers || spareHeaders),
    },
    errorHandlers,
    exchanges: {
      ...exchanges,
      request: [...(exchanges?.request || []), getRequestExchange],
    },
  }).catch((error) => cond([[T, always(Promise.reject(error))]])(error));
};
