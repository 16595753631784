import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {CircularLoader} from 'library/components/loader';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {all, any, equals, map, not, pathOr, propOr, test} from 'ramda';
import {useState} from 'react';
import {createHotel} from 'slices/create_hotelier_object/lib/helpers';
import {selectCreateHotelierObject} from 'slices/create_hotelier_object/store';
import {useAppSelector} from 'source/store';
import {patterns} from 'source/utilities/patterns';
import {isFilledProperties} from 'source/utilities/rambda';

const Wrapper = styled.div`
  width: 100%;
  min-height: 58px;
  max-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    justify-content: start;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CreateButton = () => {
  const router = useRouter();
  const {t} = useTranslation('create_hotelier_object');
  const [loading, setLoading] = useState(false);
  const {address, email, phone, lat, lng, hotel_type_id, translations} =
    useAppSelector(selectCreateHotelierObject);

  const firstTranslation = propOr(null, '0')(translations);
  const hotelName = pathOr('', ['name'])(firstTranslation);

  const filled = isFilledProperties(
    address,
    email,
    phone,
    lat,
    lng,
    hotel_type_id,
    hotelName,
  );

  const formatted = all(
    equals(true),
    map((item) => test(patterns[item.key], String(item.value)), [
      {key: 'email', value: email},
      {key: 'phone', value: phone},
      {key: 'textNotEmpty', value: hotelName},
    ] as const),
  );

  const handleCreate = () => {
    const payload: HotelCreateBody = {
      lat,
      lng,
      hotel_type_id,
      translations,
      phone,
      email,
      address,
    };

    createHotel(payload, () => router.push('/manage'), setLoading);
  };

  return (
    <Wrapper>
      <StyledButton
        onClick={handleCreate}
        disabled={any(equals(true), [loading, not(filled), not(formatted)])}
      >
        {loading ? <CircularLoader size={32} /> : t('create_button_label')}
      </StyledButton>
    </Wrapper>
  );
};

export default CreateButton;
