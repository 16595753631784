import {request} from 'library/api';
import {path} from 'ramda';
import { HttpStatusCode } from 'requestify-lite'
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  AuthLoginCreateResponse,
  AuthMeListResponse,
  AuthPasswordRequestCreateResponse,
  AuthPasswordResetCreateResponse,
  AuthRegisterCreateResponse,
  AuthRegisterTemporarySessionCreateRequest,
  AuthVerifyEmailRequestCreateResponse,
} from 'types/api-scheme';

type GetUserContext = {
  headers?: Headers;
  signal?: AbortSignal;
};
const getUser = ({headers, signal}: GetUserContext) =>
  request<AuthMeListResponse>(url('auth/me'), {
    method: 'GET',
    headers,
    signal,
  }).then(path<AuthMeListResponse['data']>([1, 'data']));

type LoginContext = {
  payload: AuthorizationFormdata;
};
const login = ({payload}: LoginContext) =>
  request<AuthLoginCreateResponse>(url('auth/login'), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<AuthLoginCreateResponse['data']>([1, 'data']));

type RegisterContext = {
  payload: ContactsFormdata;
};
const register = ({payload}: RegisterContext) =>
  request<AuthRegisterCreateResponse>(url('auth/register'), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path([1, 'data']));

type VerifyEmailContext = {
  payload: {
    email: string;
  };
};
const verifyEmail = ({payload}: VerifyEmailContext) =>
  request<AuthVerifyEmailRequestCreateResponse>(
    url('auth/verify/email/request'),
    {
      method: 'POST',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    },
  );

type RequestPasswordContext = {
  payload: PasswordRequestFormdata;
};
const requestPassword = ({payload}: RequestPasswordContext) =>
  request<AuthPasswordRequestCreateResponse>(url('auth/password/request'), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  });

type ResetPasswordContext = {
  payload: PasswordResetFormdata;
};
const resetPassword = ({payload}: ResetPasswordContext) =>
  request<AuthPasswordResetCreateResponse>(url('auth/password/reset'), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  });

type RegisterTemporarySession = {
  payload: AuthRegisterTemporarySessionCreateRequest;
  headers: Headers;
};
const registerTemporarySession = ({
  payload,
  headers,
}: RegisterTemporarySession) =>
  request<AuthPasswordResetCreateResponse>(
    url('auth/register-temporary-session'),
    {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    },
  ).then(path<TemporarySession>([1, 'data']));

export const authApi = {
  get: getUser,
  login,
  register,
  verifyEmail,
  requestPassword,
  resetPassword,
  registerTemporarySession,
};
