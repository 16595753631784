import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import {MOBILE_LAYOUT_BREAKPOINT} from '../../lib/constants';

export const LinkListWrapper = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  margin-right: 20px;

  @media (max-width: ${MOBILE_LAYOUT_BREAKPOINT}px) {
    margin-right: 0;
  }
`;

export const LinkListItem = styled.li`
  list-style: none;
`;

export const LinkListTitle = styled(Text)`
  font-family: ${({theme}) => theme.fontFamily.SOURCESANS3};
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  @media (max-width: 450px) {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledText = styled(Text)`
  font-family: ${({theme}) => theme.fontFamily.SOURCESANS3};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 470px) {
    font-size: clamp(11px, 3.4vw, 16px);
    line-height: clamp(14px, 4.4vw, 22px);
  }
`;

export const StyledTextBold = styled(StyledText)`
  font-weight: 700;
`;
