import styled from '@emotion/styled';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Select} from 'library/components/select';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useState} from 'react';
import {getMaxInputValueByUnitType} from 'slices/rate/lib/constants';
import {CONDITIONS_PAYMENTS_METHODS_MOCK} from 'slices/rate/lib/mocks';
import {useAppDispatch, useAppSelector} from 'source/store';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {patterns} from 'source/utilities/patterns';
import {RealtyProvider} from 'source/components/realty-provider';
import {
  ECancellationRateType,
  EUnitConditionsType,
  EUnitRateType,
} from '../../lib/types';
import {
  selectRate,
  setCancellationDays,
  setCancellationType,
  setCancellationUnitType,
  setCancellationValue,
  setConditionType,
} from '../../store';
import {CheckboxButton} from '../checkbox-button';
import ConditionsPayments from '../conditions-payments';

const WrapperFine = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  margin-bottom: 41px;
`;

const ConditionCancellableWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  margin-bottom: 49px;
`;

const ConditionCancellableTitle = styled(Text)`
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
`;

const ConditionCancellableCheckboxWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  @media (max-width: 480px) {
    margin-top: 20px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  &:last-child {
    margin-right: 0px;
  }
`;

const Label = styled(LabeledComponent)`
  border-radius: 100px;
  margin-bottom: 21px;
`;

const InputWrapper = styled.div`
  max-width: 440px;
`;

const PaymentMethodsWrapper = styled(LabeledComponent)``;

const PaymentTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
`;

const PaymentsBorder = styled.div`
  margin-bottom: 31px;
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  width: 100%;
  height: 1px;
`;

const PaymentMethodsSettingsButton = styled(Text)`
  color: ${({theme}) => theme.palette.fontPrimary};
  cursor: pointer;
`;

const PaymentMethodsTitle = styled(Text)`
  margin: 20px 0px 20px 0px;
`;

const PaymentMethodsSubtitle = styled(Text)`
  margin: 7px 0px 0px 10px;
`;

const SelectUnitType = styled(Select)`
  margin-bottom: 20px;
`;

const Conditions = () => {
  const {t} = useTranslation('rate');
  const dispatch = useAppDispatch();

  const {cancellation_type, cancellation_fine, payment_settings} =
    useAppSelector(selectRate).rate;
  const {required_card_warranty = false, required_prepayment_amount = 0} =
    (payment_settings as Rate['payment_settings']) || {};

  const unitType = cancellation_fine?.unit_type?.value as EUnitRateType;
  const fineType = cancellation_fine?.type as EUnitConditionsType;

  const [openPaymentSettings, setOpenPaymentSettings] =
    useState<boolean>(false);

  const mobile = useDeviceDetection('mobile');

  const textSize = mobile ? 'S' : 'M';

  const selectCancellationType = (type: ECancellationRateType) => {
    dispatch(setCancellationType(type));
  };

  const selectConditionsType = (type: EUnitConditionsType) => {
    dispatch(setConditionType(type));
  };

  return (
    <>
      <WrapperFine>
        <ConditionCancellableWrapper isMobile={mobile}>
          <ConditionCancellableTitle size={textSize}>
            {t('conditions.condition_cancelled_title')}
          </ConditionCancellableTitle>
          <ConditionCancellableCheckboxWrapper>
            <CheckboxButton
              onClick={() =>
                selectCancellationType(
                  ECancellationRateType.WithoutFineForNoShow,
                )
              }
              isSelect={
                cancellation_type?.value ===
                ECancellationRateType.WithoutFineForNoShow
              }
              isMobile={mobile}
            >
              {t('conditions.condition_cancelled_without_fine')}
            </CheckboxButton>
            <CheckboxButton
              onClick={() =>
                selectCancellationType(ECancellationRateType.NonRefundable)
              }
              isSelect={
                cancellation_type?.value === ECancellationRateType.NonRefundable
              }
              isMobile={mobile}
            >
              {t('conditions.condition_cancelled_non_refundable')}
            </CheckboxButton>
            <CheckboxButton
              onClick={() =>
                selectCancellationType(ECancellationRateType.FlexibleRules)
              }
              isSelect={
                cancellation_type?.value === ECancellationRateType.FlexibleRules
              }
              isMobile={mobile}
            >
              {t('conditions.condition_cancelled_flexible_rules')}
            </CheckboxButton>
          </ConditionCancellableCheckboxWrapper>
        </ConditionCancellableWrapper>
        {cancellation_type?.value === ECancellationRateType.FlexibleRules && (
          <>
            <InputWrapper>
              <Label
                size="S"
                text={t('conditions.penalty_days')}
                gap={12}
                color={theme.palette.fontSecondary}
              >
                <Input
                  label=""
                  type="number"
                  value={
                    cancellation_fine?.days === 0
                      ? ''
                      : String(cancellation_fine?.days)
                  }
                  required
                  pattern={patterns.compatible('positiveNumber')}
                  errorMessage={t('common.inputs.errors.positive_number')}
                  onChange={({target}) =>
                    dispatch(setCancellationDays(Number(target.value)))
                  }
                  placeholder={t('common.inputs.day_placeholder')}
                />
              </Label>
            </InputWrapper>
            <Label
              size="S"
              text={t('conditions.penalty_unit')}
              gap={12}
              color={theme.palette.fontSecondary}
            >
              <ConditionCancellableCheckboxWrapper>
                <CheckboxButton
                  onClick={() =>
                    selectConditionsType(EUnitConditionsType.OneNight)
                  }
                  isSelect={fineType === EUnitConditionsType.OneNight}
                  isMobile={mobile}
                >
                  {t('conditions.nights')}
                </CheckboxButton>
                <CheckboxButton
                  onClick={() =>
                    selectConditionsType(EUnitConditionsType.FiftyPercents)
                  }
                  isSelect={fineType === EUnitConditionsType.FiftyPercents}
                  isMobile={mobile}
                >
                  {t('conditions.percent_of_price')}
                </CheckboxButton>
                <CheckboxButton
                  onClick={() =>
                    selectConditionsType(EUnitConditionsType.FullAmount)
                  }
                  isSelect={fineType === EUnitConditionsType.FullAmount}
                  isMobile={mobile}
                >
                  {t('conditions.money')}
                </CheckboxButton>
                <CheckboxButton
                  onClick={() =>
                    selectConditionsType(EUnitConditionsType.Other)
                  }
                  isSelect={fineType === EUnitConditionsType.Other}
                  isMobile={mobile}
                >
                  {t('conditions.other')}
                </CheckboxButton>
              </ConditionCancellableCheckboxWrapper>
            </Label>
            {fineType === EUnitConditionsType.Other && (
              <InputWrapper>
                <Label
                  size="S"
                  text={t('conditions.penalty_amount')}
                  gap={12}
                  color={theme.palette.fontSecondary}
                >
                  <SelectUnitType
                    value={{
                      value: cancellation_fine?.type,
                      label:
                        cancellation_fine?.unit_type?.title ||
                        CONDITIONS_PAYMENTS_METHODS_MOCK[0].label,
                    }}
                    options={CONDITIONS_PAYMENTS_METHODS_MOCK}
                    setValue={(option) =>
                      dispatch(setCancellationUnitType(option))
                    }
                  />
                  <Input
                    label=""
                    type="number"
                    value={
                      cancellation_fine?.value === 0
                        ? ''
                        : String(cancellation_fine?.value)
                    }
                    required
                    pattern={patterns.compatible('positiveNumber')}
                    errorMessage={t('common.inputs.errors.positive_number')}
                    placeholder={
                      unitType === EUnitRateType.Percent
                        ? t('common.inputs.percent_placeholder')
                        : t('common.inputs.money_placeholder', {
                            currency: t('common:units_payments.rub'),
                          })
                    }
                    maxCount={getMaxInputValueByUnitType[unitType]}
                    onChange={({target}) =>
                      dispatch(setCancellationValue(Number(target.value)))
                    }
                  />
                </Label>
              </InputWrapper>
            )}
          </>
        )}
      </WrapperFine>
      {/* TODO от 19.06.2024 - скрыть пункт “Способ оплаты” до нового года */}
      {/* <RealtyProvider>
        <PaymentMethodsWrapper
          size="L"
          text={t('conditions.payment_methods')}
          gap={12}
          color={theme.palette.fontDefault}
        >
          <PaymentsBorder />
          <PaymentTitleWrapper>
            <ConditionCancellableTitle size="M">
              {t('conditions.online_or_hotel')}
            </ConditionCancellableTitle>
            <PaymentMethodsSettingsButton
              size="S"
              onClick={() => setOpenPaymentSettings(true)}
            >
              {t('conditions.payment_settings')}
            </PaymentMethodsSettingsButton>
          </PaymentTitleWrapper>
          <PaymentMethodsTitle size="S">
            {t('conditions.booking_guarantee')}
          </PaymentMethodsTitle>
          <PaymentMethodsSubtitle size="S">
            {t('conditions.prepayment_required', {
              prepayment:
                Number(required_prepayment_amount) > 0
                  ? t('common.need')
                  : t('common.no_need'),
            })}
          </PaymentMethodsSubtitle>
          <PaymentMethodsSubtitle size="S">
            {t('conditions.card_warranty_required', {
              card_warranty: required_card_warranty
                ? t('common.need')
                : t('common.no_need'),
            })}
          </PaymentMethodsSubtitle>
        </PaymentMethodsWrapper>

        {openPaymentSettings && (
          <ConditionsPayments
            open={openPaymentSettings}
            onClose={() => setOpenPaymentSettings(false)}
          />
        )}
      </RealtyProvider> */}
    </>
  );
};

export default Conditions;
