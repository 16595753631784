import useTranslation from 'next-translate/useTranslation';
import {Link} from 'source/components/link';
import {Routes} from 'source/utilities/network/url';
import {
  LinkList,
  LinkListItem,
  LinkListTitle,
  LinkListWrapper,
  StyledText,
  StyledTextBold,
} from '../link-list';
import {KNOWLEDGE_BASE, ZHILIBYLI_OFFER} from '../../lib/constants';

export const HoteliersColumn = () => {
  const {t} = useTranslation('footer');

  return (
    <LinkListWrapper>
      <LinkListTitle size="boldM">{t('hoteliers')}</LinkListTitle>
      <LinkList>
        <LinkListItem>
          <Link href={KNOWLEDGE_BASE}>
            <StyledTextBold size="boldS"> {t('help_hoteliers')}</StyledTextBold>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.registration}>
            <StyledTextBold size="boldS">
              {' '}
              {t('own_hotel_registration')}
            </StyledTextBold>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.authorization}>
            <StyledText size="S"> {t('personal_account')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.verifiedHotels}>
            <StyledText size="S"> {t('verification_advantages')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ZHILIBYLI_OFFER}>
            <StyledText size="S"> {t('offer')}</StyledText>
          </Link>
        </LinkListItem>
      </LinkList>
    </LinkListWrapper>
  );
};
