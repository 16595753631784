import { FingerprintService } from 'source/services/finger-print'
import { isServer } from 'source/utilities/guards/environment'

export async function fingerPrintExchange() {
  if (isServer()) {
    return {}
  }

  const fingerprint = FingerprintService.get()

  if (FingerprintService.isValid(fingerprint)) {
    return {
      [FingerprintService.STORAGE_KEY]: fingerprint,
    }
  }

  const updatedFingerprint = await FingerprintService.load()

  return {
    [FingerprintService.STORAGE_KEY]: updatedFingerprint,
  }
}
