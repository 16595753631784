import type {CookieAttributes} from 'js-cookie';
import Cookies from 'js-cookie';
import {IS_LOCALHOST, isServer} from '../guards/environment';

export enum CookieNames {
  HOTELIER_AUTHORIZATION = 'hoteliers_authorization',
  TEMPORARY_AUTHORIZATION = 'hoteliers_temporary_authorization',
}

export const getCookie = (name: CookieNames | string) => {
  if (isServer()) {
    return '';
  }

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()!.split(';').shift();
  }
};

export const deleteCookie = (key: CookieNames | string, path: string = '/') => {
  const options: CookieAttributes = IS_LOCALHOST
    ? {
        expires: 365,
        path,
      }
    : {
        expires: 365,
        path,
        secure: true,
        sameSite: 'None',
        domain: `.${window.location.host}`,
      };
  Cookies.remove(key, options);
};

export const setCookie = (
  key: CookieNames | string,
  content: string,
  path: string = '/',
  domain = `.${window.location.host}`,
) => {
  const options: CookieAttributes = IS_LOCALHOST
    ? {
        expires: 365,
        path,
      }
    : {
        expires: 365,
        path,
        secure: true,
        sameSite: 'None',
        domain,
      };
  Cookies.set(key, content, options);
};
