import {
  FINGERPRINT_COOKIE_KEY,
  FINGERPRINT_MIN_VALUE_LENGTH,
  FINGERPRINT_PUBLIC_KEY,
  HOSTNAME,
} from 'source/utilities/guards/environment';
import {getCookie, setCookie} from 'source/utilities/network/cookies';
import {FingerPrintService as LibraryFingerPrint} from 'zb-fingerprint';

export const FingerprintService = new LibraryFingerPrint({
  cookie: {
    get: (key: string) => {
      const value = getCookie(key);
      return value ?? null;
    },
    set: (key, value) => {
      setCookie(key, value, '/', HOSTNAME.replace(/\*\*/g, ''));
    },
  },
  minLength: FINGERPRINT_MIN_VALUE_LENGTH,
  publicKey: FINGERPRINT_PUBLIC_KEY,
  storageKey: FINGERPRINT_COOKIE_KEY,
});
