import {
  buildRouteToHelp,
  buildRouteToMain,
  KNOWLEDGE_BASE_ROUTES,
  MAIN_ZHILIBYLI_ROUTES,
} from 'source/utilities/url';

export const MOBILE_LAYOUT_BREAKPOINT = 1200;
export const PAYMENT_METHODS_ICONS_SIZE = {
  width: 36,
  height: 24,
};
export const DEFAULT_IOS = 'ios';
export const DEFAULT_ANDROID = 'android';

export const MOBILE_APPS_URL = buildRouteToMain(
  MAIN_ZHILIBYLI_ROUTES.mobileApps,
);

export const ZHILIBYLI_ABOUT = buildRouteToMain(MAIN_ZHILIBYLI_ROUTES.about);

export const ZHILIBYLI_AFFILIATE = buildRouteToMain(
  MAIN_ZHILIBYLI_ROUTES.affiliate,
);

export const ZHILIBYLI_POLITICS = buildRouteToMain(
  MAIN_ZHILIBYLI_ROUTES.politics,
);

export const ZHILIBYLI_RULES = buildRouteToMain(MAIN_ZHILIBYLI_ROUTES.rules);

export const ZHILIBYLI_SOFTWARE = buildRouteToMain(
  MAIN_ZHILIBYLI_ROUTES.software,
);

export const ZHILIBYLI_PUBLIC_OFFER_AGREEMENT = buildRouteToMain(
  MAIN_ZHILIBYLI_ROUTES.publicOfferAgreement,
);

export const KNOWLEDGE_BASE = buildRouteToHelp(
  KNOWLEDGE_BASE_ROUTES.knowledgeBase,
);

export const ZHILIBYLI_OFFER = buildRouteToMain(MAIN_ZHILIBYLI_ROUTES.offer);
