const DEFAULT_BED_TYPE = 1;
export const DEFAULT_ROOM_ID = -1;
export const UNDEFINED_CATEGORY = -1;
export const SUB_CATEGORY_INDEX = 3;

export const DEFAULT_BED: Bed = {
  directory_bed_type_id: DEFAULT_BED_TYPE,
  is_additional: false,
  is_alternative: false,
};

export const DEFAULT_ADDITIONAL_BED: Bed = {
  directory_bed_type_id: DEFAULT_BED_TYPE,
  is_additional: true,
  is_alternative: false,
};

export const DEFAULT_ALTERNATIVE_BED: Bed = {
  directory_bed_type_id: DEFAULT_BED_TYPE,
  is_additional: false,
  is_alternative: true,
};

export const DEFAULT_ALTERNATIVE_ADDITIONAL_BED: Bed = {
  directory_bed_type_id: DEFAULT_BED_TYPE,
  is_additional: true,
  is_alternative: true,
};

export const DEFAULT_BEDROOM = {
  beds: [DEFAULT_BED],
};

export const MAX_AREA_VALUE = 10000;
export const MAX_ROOMS_VALUE = 1000;
export const MAX_GUEST_VALUE = 100;
export const MAX_FLOORS = 200;
export const MIN_ROOM_DESCRIPTION_LENGTH = 120;
export const MAX_ROOM_DESCRIPTION_LENGTH = 5000;
