import styled from '@emotion/styled';
import TextEditor from 'library/components/text-editor';
import {Text} from 'library/components/text';
import {useAppDispatch, useAppSelector} from 'source/store';
import {FC, useEffect, useState} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {EMPTY_DESCRIPTION_INPUT_VALUE} from 'slices/general_info/lib/constants';
import {
  MAX_ROOM_DESCRIPTION_LENGTH,
  MIN_ROOM_DESCRIPTION_LENGTH,
} from '../lib/constants';
import {selectRoom, setDescription} from '../store';

const Wrapper = styled.div`
  margin: 13px 0 35px 0;
`;

const Title = styled(Text)`
  margin-right: 10px;
  color: ${({theme}) => theme.palette.fontPrimary};
  margin-bottom: 15px;
`;

const StyledTextEditor = styled(TextEditor)`
  margin-bottom: 13px;

  .ql-container {
    height: 220px;
  }
`;

const Counter = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
`;

interface Props {
  isLoading: boolean;
  translateKey: string;
}

export const RoomDescriptionEditor: FC<Props> = ({isLoading, translateKey}) => {
  const {t} = useTranslation(translateKey);
  const room = useAppSelector(selectRoom);
  const dispatch = useAppDispatch();
  const [htmlInner, setHTMLInner] = useState('');

  const handleEditRoomDescription = (value: string) => {
    setHTMLInner(value);
    dispatch(setDescription(value));
  };

  useEffect(() => {
    if (htmlInner === EMPTY_DESCRIPTION_INPUT_VALUE) {
      setHTMLInner('');
      dispatch(setDescription(null));
    }
  }, [htmlInner]);

  return (
    <Wrapper>
      <Title size="S">
        {t(
          room.description
            ? 'room_description.edit_room_description'
            : 'room_description.add_room_description',
        )}
      </Title>
      <StyledTextEditor
        defaultValue={room.description || htmlInner}
        value={room.description || htmlInner}
        onChange={handleEditRoomDescription}
        readOnly={isLoading}
      />
      <Counter size="S">
        {t('room_description.min_description_room', {
          min: MIN_ROOM_DESCRIPTION_LENGTH,
          max: MAX_ROOM_DESCRIPTION_LENGTH,
        })}
      </Counter>
    </Wrapper>
  );
};
