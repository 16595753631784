/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'source/store';
import {DEFAULT_BEDROOM, DEFAULT_ROOM_ID} from './lib/constants';
import {
  getBedFilters,
  getDefaultBed,
  isAdditionalBed,
  isAlternativeAdditionalBed,
  isAlternativeBed,
  isDefaultBed,
} from './lib/helpers';
import {BedType, SetBedsActionType} from './lib/types';

const initialState: Room = {
  id: DEFAULT_ROOM_ID,
  name: '',
  baths_number: 0,
  description: '',
  area: 0,
  rooms_number: 0,
  max_guests_number: 0,
  is_baby_bed_available: false,
  room_category_id: 3, // Номер эконом (дефолтный вариант)
  bedrooms: [DEFAULT_BEDROOM],
  room_parent_category_ids: [],
  facilities: [],
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setBathNumber: (state, action: PayloadAction<number>) => {
      state.baths_number = action.payload;
    },
    setDescription: (state, action: PayloadAction<string | null>) => {
      state.description = action.payload;
    },
    setArea: (state, action: PayloadAction<number>) => {
      state.area = action.payload;
    },
    setRoomsNumber: (state, action: PayloadAction<number>) => {
      state.rooms_number = action.payload;
    },
    setGuestNumber: (state, action: PayloadAction<number>) => {
      state.max_guests_number = action.payload;
    },
    setCategory: (state, action: PayloadAction<number>) => {
      state.room_category_id = action.payload;
    },
    setBabyBedAvailable: (state, action: PayloadAction<boolean>) => {
      state.is_baby_bed_available = action.payload;
    },
    setBedrooms: (state, action: PayloadAction<number>) => {
      if (state.bedrooms && state.bedrooms.length < action.payload) {
        state.bedrooms = [
          ...state.bedrooms,
          ...Array.from({length: action.payload - state.bedrooms.length}).map(
            () => DEFAULT_BEDROOM,
          ),
        ];
      } else if (state.bedrooms && state.bedrooms.length > action.payload) {
        state.bedrooms = state.bedrooms.slice(0, action.payload);
      }
    },
    setBeds: (state, action: PayloadAction<SetBedsActionType>) => {
      const {roomIndex, bedsNumber, bedType} = action.payload;
      const {isBedWithTrait, isBedWithoutTrait} = getBedFilters(bedType);
      const bed = getDefaultBed(bedType);
      const currentAllBeds = state.bedrooms?.[roomIndex]?.beds || [];

      const currentBeds = currentAllBeds.filter(isBedWithTrait);
      const otherBeds = currentAllBeds.filter(isBedWithoutTrait);

      const currentBedsNumber = currentBeds.length;

      if (!state.bedrooms) {
        return;
      }

      if (currentBedsNumber < bedsNumber) {
        state.bedrooms[roomIndex].beds = [
          ...otherBeds,
          ...currentBeds,
          ...Array.from({length: bedsNumber - currentBedsNumber}).map(
            () => bed,
          ),
        ];
      }
      if (currentBedsNumber > bedsNumber) {
        state.bedrooms[roomIndex].beds = [
          ...otherBeds,
          ...currentBeds.slice(0, bedsNumber),
        ];
      }
    },
    setBedType: (
      state,
      action: PayloadAction<{
        roomIndex: number;
        bedIndex: number;
        bedType: BedType;
        type: number;
      }>,
    ) => {
      const {roomIndex, bedIndex, type, bedType} = action.payload;
      const {isBedWithTrait, isBedWithoutTrait} = getBedFilters(bedType);
      const currentAllBeds = state.bedrooms?.[roomIndex]?.beds || [];

      const currentBeds = currentAllBeds.filter(isBedWithTrait);
      const otherBeds = currentAllBeds.filter(isBedWithoutTrait);

      currentBeds[bedIndex].directory_bed_type_id = type;

      if (state.bedrooms) {
        state.bedrooms[roomIndex].beds = [...otherBeds, ...currentBeds];
      }
    },
    toggleFacility: (state, action: PayloadAction<number>) => {
      const {facilities} = state;
      const facilityId = action.payload;
      if (facilities) {
        const isFound = facilities.some(({id}) => id === facilityId);
        state.facilities = isFound
          ? facilities.filter(({id}) => id !== facilityId)
          : [...facilities, {id: facilityId}];
      } else {
        state.facilities = [{id: facilityId}];
      }
    },
    setRoom: (state, action: PayloadAction<Room>) => {
      return action.payload;
    },
    setFloors: (state, action: PayloadAction<Room['floors']>) => {
      state.floors = action.payload;
    },
  },
});

export const {
  reset,
  setName,
  setArea,
  setCategory,
  setBeds,
  setBedrooms,
  setRoomsNumber,
  setGuestNumber,
  setBathNumber,
  setDescription,
  setBabyBedAvailable,
  setBedType,
  toggleFacility,
  setRoom,
  setFloors,
} = roomSlice.actions;

export const selectRoomDescription = (state: RootState) => {
  return {
    bedRooms: state.room.bedrooms?.length || 0,
    bathRooms: state.room.baths_number,
    name: state.room.name,
    area: state.room.area,
    description: state.room.description,
    guestNumber: state.room.max_guests_number || 0,
    roomsNumber: state.room.rooms_number || 0,
    babyBedAvailable: state.room.is_baby_bed_available,
    room_parent_category_ids: state.room.room_parent_category_ids,
    room_category_id: state.room.room_category_id,
    id: state.room.id,
  };
};

export const selectRoomArea = (state: RootState) => state.room.area;

export const selectRoomsNumber = (state: RootState) =>
  state.room.rooms_number || initialState.rooms_number;

export const selectRoomBabyBedAvailable = (state: RootState) =>
  state.room.is_baby_bed_available;

export const selectRoomBathrooms = (state: RootState) =>
  state.room.baths_number;

export const selectDescription = (state: RootState) => state.room.description;

export const selectRoomParentCategoryIds = (state: RootState) =>
  state.room.room_parent_category_ids;

export const selectRoomCategoryId = (state: RootState) =>
  state.room.room_category_id;

export const selectRoomId = (state: RootState) => state.room.id;

export const selectFloors = (state: RootState) => state.room.floors;

export const selectGuestNumber = (state: RootState) =>
  state.room.max_guests_number || initialState.max_guests_number;

export const selectRoomName = (state: RootState) => state.room.name;

export const selectRoomBedrooms = (state: RootState) =>
  state.room.bedrooms?.length || 0;

export const selectRoom = (state: RootState) => {
  return state.room;
};

export const selectBeds = (state: RootState, roomIndex: number) => {
  const beds = state.room.bedrooms?.[roomIndex]?.beds || [];
  const defaultBeds = beds.filter(isDefaultBed);
  const additionalBeds = beds.filter(isAdditionalBed);
  const alternativeBeds = beds.filter(isAlternativeBed);
  const alternativeAdditionalBeds = beds.filter(isAlternativeAdditionalBed);
  return {
    beds: defaultBeds,
    addBeds: additionalBeds,
    altBeds: alternativeBeds,
    altAddBeds: alternativeAdditionalBeds,
  };
};

export const selectIsValid = (state: RootState) => {
  return (
    state.room.name &&
    state.room.area > 0 &&
    state.room.max_guests_number > 0 &&
    state.room.rooms_number > 0
  );
};

export const selectAmenities = (state: RootState) => {
  return state.room.facilities;
};

export default roomSlice.reducer;
